import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Descriptions, Typography, Select, Breadcrumb, Spin, InputNumber, Input } from 'antd';
import './Assessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
// import QuestionCard from '../../components/assessment/QuestionCard';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import Utils from '../../misc/Utils';
import Split from 'react-split';
import moment from 'moment';
// import * as AntDIcons from '@ant-design/icons';
// import './SplitPanel.css';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Paragraph } = Typography;
// const { Option } = Select;
const { TextArea } = Input;

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let matched = false;
let essayIdx = -1;

export function StudentFormativeViewPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();
   // const [assessment_id, setId] = useState(location.state.assessment_id);
   const assignInfo = location.state.assignInfo;
   // const assignment_id = location.state.assignment_id;
   const origin = location.state.origin;
   const assessmentId = location.state.assessment_id;
   const studentId = location.state.student_id;

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   const [answerInfo, setAnswerInfo] = useState({
      Id: "",
      Answers: "",
      Score: "",
      Total_points: "",
      Start_date: "",
      End_date: ""
   });

   useEffect(() => {
      Utils.verifyJwt();

      essayIdx = -1;

      getAssessmentInfo(assessmentId);
   }, [location.state.assessment_id]);

   const getAssessmentInfo = (id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/info?id=${id}&at=formative`).then((response) => {
         if (response.data) {
            const data = response.data;
            setAssInfo({
               Id: data.id,
               Title: data.title,
               Term: data.term,
               File_url: data.file_url,
               Question_type: data.question_type,
               Created_by: data.created_by,
               Date_created: data.date_created,
               Question_sheet: JSON.parse(data.question_sheet),
               Level: data.level,
               Subject: data.subject,
               Level_desc: data.level_desc,
               Subject_desc: data.subject_desc
            });

            getAnswerInfo(assessmentId, assignInfo.id, studentId);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAnswerInfo = (assessment_id, assignment_id, student_id) => {
      axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/answerinfo2?assessment_id=${assessment_id}&assignment_id=${assignment_id}&student_id=${student_id}&at=formative`).then((response) => {
         if (response.data) {
            const data = response.data;
            // const answers = JSON.parse(data.answer);
            // setAnswers(JSON.parse(data.answer));
            setAnswerInfo({
               Id: data.id,
               Answers: JSON.parse(data.answer),
               Score: data.score + data.essay_score,
               Total_points: data.total_points,
               Start_date: moment(data.start_date).format("MMM DD, YYYY hh:mm a"),
               End_date: moment(data.end_date).format("MMM DD, YYYY hh:mm a"),
               Essay_score_multi: data.essay_score_multi !== "" && data.essay_score_multi !== null ? JSON.parse(data.essay_score_multi) : [],
               Essay_multi_comments: data.essay_multi_comments !== "" && data.essay_multi_comments !== null ? JSON.parse(data.essay_multi_comments) : [],
               Essay_score: data.essay_score,
            })
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/studentformativeassessment', { state: { activetab: origin } })}>Formative Assessments</a></li>
                           <li><span>/</span></li>
                           <li className="active">View</li>
                        </ul> */}

                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              {/* <AntDIcons.HomeOutlined /> */}
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <Link to="/studentformativeassessment" state={{ activetab: origin }}>Formative Assessments</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>View</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>

                     {answerInfo.Answers.length > 0
                        ?
                        <div className="pdf-all-page-container">
                           <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
                              <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                 <Viewer
                                    pageLayout={{
                                       transformSize: ({ size }) => ({
                                          height: size.height + 30,
                                          width: size.width + 30,
                                       }),
                                    }}
                                    fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}
                                    defaultScale={SpecialZoomLevel.PageWidth}
                                    initialPage={0}
                                 />
                              </Worker>
                           </div>
                           {/* <RenderPDFReader url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} /> */}
                           {/* <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} /> */}
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <>
                        {answerInfo.Answers.length > 0
                           ?
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                                    <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{assInfo.Title}</b></Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Score"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{answerInfo.Score + " of " + answerInfo.Total_points}</b></Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Duration"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{Math.floor((Math.abs(new Date(answerInfo.End_date) - new Date(answerInfo.Start_date)) / 1000 / 60))} min(s)</b></Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Date"} span={4} style={{ textAlign: "center", width: "50%" }}>
                                       <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{assignInfo.start_date + " to " + assignInfo.end_date}</b></Paragraph>
                                    </Descriptions.Item>
                                 </Descriptions>

                                 <ul className="questions">
                                    {assInfo.Question_sheet.map(({ id, type, points, correct, option_labels, image_url, question }, idx) => {

                                       if (type == "section") {
                                          qcardIdx = 1;
                                          idxCol.push(sectionIdx);
                                          sectionIdx++;
                                       } else {
                                          idxCol.push(qcardIdx);
                                          qcardIdx++;
                                       }

                                       //-- Check if answer and correct are matched 
                                       var matched = false;

                                       if (type !== "long_answer" && type !== "section") {
                                          if (type === "short_answer") {
                                             // correct.forEach(element => {
                                             //    if (element === answerInfo.Answers[idx].answer) {
                                             //       cntMatched++;
                                             //    }
                                             // });
                                             for (var i = 0; i < correct.length; i++) {
                                                //-- Remove all white spaces before comparing
                                                var tmp_correct = correct[i].replace(/\s/g, '');
                                                var tmp_answer = answerInfo.Answers[idx].answer[0].replace(/\s/g, '');

                                                // if (correct[i].toLowerCase().trim() === answerInfo.Answers[idx].answer[0].toLowerCase().trim()) {
                                                if (tmp_correct.toLowerCase() === tmp_answer.toLowerCase()) {
                                                   matched = true;
                                                   break;
                                                }
                                             }
                                          } else {
                                             var cntMatched = 0;
                                             for (var i = 0; i < correct.length; i++) {
                                                if (correct[i] === answerInfo.Answers[idx].answer[i]) {
                                                   cntMatched++;
                                                }
                                             }

                                             if (cntMatched === correct.length)
                                                matched = true;
                                          }
                                       }

                                       if (type === "long_answer")
                                          essayIdx++;

                                       return (
                                          <li>
                                             <BraineeQuestionCard
                                                key={id}
                                                index={idxCol[idx]}
                                                id={idx}
                                                type={type}
                                                points={points}
                                                correct={correct}
                                                answers={answerInfo.Answers[idx].answer}
                                                option_labels={option_labels}
                                                image_url={image_url}
                                                question={question}
                                                question_type={'1'}
                                                mode={'student-view'}
                                                matched={matched}
                                                assessment_type={'formative'}
                                                essay_score={answerInfo.Essay_score_multi[essayIdx]}
                                                essay_comment={answerInfo.Essay_multi_comments[essayIdx]}
                                             />
                                             {/* {type === "long_answer" &&
                                                <>
                                                   <Descriptions bordered size='small' column={4} style={{ marginBottom: "20px" }}>
                                                      <Descriptions.Item label={"Points (Max: " + points + ")"} span={4} style={{ fontSize: 13 }}>
                                                         <InputNumber
                                                            id={essayIdx}
                                                            disabled
                                                            size='middle'
                                                            // max={points} 
                                                            // min={0} 
                                                            style={{ width: "100%", borderRadius: "7px" }}
                                                            defaultValue={answerInfo.Essay_score_multi[essayIdx]}
                                                         />
                                                      </Descriptions.Item>
                                                      <Descriptions.Item label="Comments/Remarks" span={4} style={{ fontSize: 13 }}>
                                                         <TextArea
                                                            // allowClear
                                                            disabled
                                                            id={essayIdx}
                                                            name={essayIdx}
                                                            rows={5}
                                                            defaultValue={answerInfo.Essay_multi_comments[essayIdx]}
                                                         />
                                                      </Descriptions.Item>
                                                   </Descriptions>
                                                </>
                                             } */}
                                          </li>
                                       );
                                    })}
                                 </ul>
                              </SimpleBarReact>
                           </div>
                           :
                           <div className='temp-answer-sheet-content2'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                        }
                     </>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
