import React, { useEffect, useRef, useState, useCallback } from 'react'
import SimpleBarReact from "simplebar-react";
import moment from 'moment';
import './Subject.scss'
import {
   Tabs, Modal, Button, Image, Typography, Divider, message, notification, Empty,
   Space, Radio, Upload, Tooltip, Progress, Breadcrumb
} from 'antd';
import ReactPlayer from 'react-player'
import Iframe from 'react-iframe'
import { EditLesson, EditQuiz } from '../../../components/lesson';
import { useSelector, useDispatch } from 'react-redux'
import { TopicSection } from '../../../components/lesson';
import { WebsiteContent } from '../../../components/lesson';
import LessonService from '../../../services/LessonService';
import Enums from '../../../misc/Enums';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate, Link } from 'react-router-dom';
import * as AntDIcons from '@ant-design/icons';
import { ChatGPT } from '../../../components/chatGPT/chatGPT';
import axios from 'axios';

import {
   set_lesson
} from '../../../actions';
import Utils from '../../../misc/Utils';
import { ChatGPTFB } from '../../../components/chatGPT/ChatGPTFB';
import ButtonGroup from 'antd/lib/button/button-group';
import DocumentViewer from '../../../components/document-viewer/DocumentViewer';
// import RenderPDFViewer from '../../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
import LessonViewer from '../Viewer/LessonViewer';
import FullScreenViewer from '../Viewer/FullScreenViewer';
import { RenderPDFReader } from '../../../components/pdfreader/RenderPDFReader';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import Wysiwyg from '../../../components/wysiwyg/wysiwyg';
import Wysiwyg2 from '../../../components/wysiwyg/wysiwyg2';

import platform from 'platform';
import uuid from 'react-uuid';
import { isMobile } from 'react-device-detect';

const { Text, Title } = Typography;
const { TabPane } = Tabs;


export function LessonEditView(props) {
   const [downloadStatus, setDownloadStatus] = React.useState(false);
   const [currentS3ContentLink, setCurrentS3ContentLink] = React.useState('');
   const [downloadOfficeFileName, setDownloadOfficeFileName] = React.useState('');
   const [currentContentLink, setCurrentContentLink] = React.useState('');
   const [currentContentLinkIdx, setCurrentContentLinkIdx] = React.useState(0);
   const [currentContentType, setCurrentContentType] = React.useState('');
   const [contentCount, setContentCount] = React.useState(0);
   const [currentSectionIdx, setCurrentSectionIdx] = React.useState(0);
   const [currentSectionContentIdx, setCurrentSectionContentIdx] = React.useState(0);
   const [contentList, setContentList] = React.useState([]);
   const [visible, setVisible] = React.useState(false);
   const [visibleModal, setVisibleModal] = React.useState(false);
   const [toggleSideMenu, setToggleSideMenu] = React.useState(false);
   const [visibleTile, setVisibleTile] = React.useState(false);
   const [visibleAI, setVisibleAI] = React.useState(false);
   const [lesson, setLesson] = React.useState();
   const [currentTopicEditIdx, setCurrentTopicEditIdx] = React.useState(0);
   const [currentLessonEditIdx, setCurrentLessonEditIdx] = React.useState(0);
   const [viewState, setViewState] = React.useState(0)
   const [overview, setOverview] = React.useState("");
   const [levelid, setLevelId] = React.useState(0);
   const [lessonid, seLessonid] = React.useState("");
   const [mode, setMode] = React.useState(props.mode);
   const [api, contextHolder] = notification.useNotification();

   const [effectiveDate, setEffectiveDate] = React.useState("");
   const [subjectDesc, setSubjectDesc] = React.useState("");
   const [quizDesc, setQuizDesc] = React.useState("");
   const [quizStatus, setQuizStatus] = React.useState(true);

   const [lessonButton, setLessonButton] = useState('0');
   const [uploadFileList, setUploadFileList] = useState([]);
   const [visibleLearningPlan, setVisibleLearningPlan] = useState(false);
   const [learningPlan, setLearningPlan] = useState('');
   const [isUploading, setIsUploading] = useState(false);
   const [percentage, setPercentage] = useState(0);
   const [showFullScreen, setShowFullScreen] = React.useState(false);
   const [fullscreenState, setFullscreenState] = useState("");
   const [version, setVersion] = useState(0);

   const editorRef = useRef(null);
   const navigate = useNavigate();

   const lessonState = useSelector(state => state.lesson)
   const dispatch = useDispatch();
   const fullScreenHandle = useFullScreenHandle();

   const role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [type, setType] = useState('');


   const processLessonData = (data) =>
   {
      if (data.sections === null)
         data.sections = []

      data = Utils.convertKeysToLowerCase(data)
      dispatch(set_lesson(data))

      setLearningPlan(data.learningplan)
      setLesson(data)
      var sectionVersion = 2
      if (data.sections.length > 0)
         sectionVersion = data.sections[currentSectionIdx]?.contents[currentSectionContentIdx]?.data?.length >= 0 ? 2 : 0

      setVersion(sectionVersion)

      if (sectionVersion === 0) {
         if (data.sections.length > 0) {
            var firstContent = data.sections[currentSectionIdx]?.contents[currentSectionContentIdx];
            if (firstContent) {
               if (firstContent.links.length > 0) {
                  if (firstContent.links[0].url !== "") {
                     var s3FullPathWithOfficeLink = ""
                     var s3FullPathName = ""

                     if (firstContent.links[0].type === "msoffice") {
                        var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
                        var s3File = firstContent.links[0].url.split(msOfficelink)
                        s3FullPathWithOfficeLink = s3File[1]
                        var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                        s3FullPathName = firstContent.links[0].title + "." + s3FileExt
                     }
                     else if (firstContent.links[0].type === "others") {
                        s3FullPathWithOfficeLink = firstContent.links[0].url
                        var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                        s3FullPathName = firstContent.links[0].title + "." + s3FileExt
                     }

                     setCurrentS3ContentLink(s3FullPathWithOfficeLink)
                     setDownloadOfficeFileName(s3FullPathName)
                  }

                  setCurrentContentLink(firstContent.links[0].url);
                  setCurrentContentType(firstContent.links[0].type);
               }
               else if (firstContent.type === "quiz") {

                  var today = moment(new Date()).format('YYYY-MM-DD');
                  //console.log("Date : " + today)
                  var current = false;

                  var tmpeffectivedate = firstContent.effectivedate;
                  var eDate = ""
                  if (tmpeffectivedate.includes("@")) {
                     var tmpdatesplit = tmpeffectivedate.split("@");
                     var startDate = moment(tmpdatesplit[0], 'YYYY-MM-DD').format('YYYY-MM-DD')
                     var endDate = moment(tmpdatesplit[1], 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
                     //console.log("Date Start : " + startDate)
                     //console.log("Date End : " + endDate)
                     //current = today.isBetween(moment(tmpdatesplit[0],'YYYY-MM-DD').format('YYYY-MM-DD'),moment(tmpdatesplit[1],'YYYY-MM-DD').add(1,'d').format("YYYY-MM-DD"), null, '[]') 
                     current = (today >= startDate && today <= endDate)
                     //console.log("Current ====: " + current)
                     eDate = tmpdatesplit[0] + " - " + tmpdatesplit[1]
                  }
                  else {
                     var effectivedate = moment(firstContent.effectivedate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                     eDate = firstContent.effectivedate + " - " + moment(firstContent.effectivedate, 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
                     // current =  today.isBefore(effectivedate) 
                     current = (today <= effectivedate)
                  }

                  setQuizStatus(current)
                  setCurrentContentLink("");
                  setCurrentContentType(firstContent.type);
                  setEffectiveDate(eDate);
                  setSubjectDesc(firstContent.otherdesc);
                  setQuizDesc(firstContent.title)
               }
               setContentCount(firstContent.links.length);
               setCurrentContentLinkIdx(0);
               setContentList(firstContent);
            }
         }
         else {
            // <LessonViewer  data={lesson.sections[currentSectionIdx]?.contents[currentSectionContentIdx]?.data} version={lesson.sectionversion}/>
         }

      }
   }

   const getLessonByID = (Id) =>
   {
      LessonService.getLessonById(Id).then((lesson) => {
         let data = lesson.data
         processLessonData(data)
      }).catch((ex) => {
         message.error(ex.message)
      })
   }

   const getResourceLessonByID = (Id) =>
      {
         LessonService.getResourcesLessonById(Id).then((lesson) => {
            let data = lesson.data
            processLessonData(data)
         }).catch((ex) => {
            message.error(ex.message)
         })
      }   

   useEffect(() => {
      Utils.verifyJwt();
      const search = window.location.search;
      const query = new URLSearchParams(search);
      const Id = query.get('Id')
      seLessonid(Id)
      setLevelId(query.get('level'))

      var type = query.get('type')
      setType(type)
      if (type === "resources")
         getResourceLessonByID(Id)
      else
         getLessonByID(Id)

   }, []);


   const _clickTileItem = (idx) => {

      if (contentList.links[idx].url !== "") {
         var s3FullPathWithOfficeLink = ""
         var s3FullPathName = ""

         if (contentList.links[idx].type === "msoffice") {
            var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
            var s3File = contentList.links[idx].url.split(msOfficelink)
            s3FullPathWithOfficeLink = s3File[1]
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }
         else if (contentList.links[idx].type === "others" || contentList.links[idx].type === "pdf") {
            s3FullPathWithOfficeLink = contentList.links[idx].url
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }

         setCurrentS3ContentLink(s3FullPathWithOfficeLink)
         setDownloadOfficeFileName(s3FullPathName)
      }

      setCurrentContentLink(contentList.links[idx].url);
      setCurrentContentType(contentList.links[idx].type);
      setCurrentContentLinkIdx(idx);
      setVisibleTile(true)
   }

   const fullscreenExit = () => {
      fullScreenHandle.exit()
   }

   const _tileView = () => {
      return <>
         <div className="container">
            <div >
               <ul class="cards">
                  {(version === 0 && contentList.type !== "quiz" && contentList.links !== undefined) && contentList.links.map((item, idx) => {
                     return (
                        <li class="cards__item">
                           <div class="card">
                              <div class="card__image " style={{ backgroundImage: `url(${item.image})` }}>
                              </div>
                              <div class="card__content">
                                 <div class="card__title"> {item.title}</div>
                                 <p class="card__text">{item.description} </p>
                                 {/* <button class="btn btn--block card__btn"  onClick={() => _clickTileItem(idx)}>Open</button> */}
                                 <Button type='default' size='middle' shape='round'
                                    onClick={() => item.type !== "search" ? _clickTileItem(idx) : window.open(item.url, '_blank').focus()}>
                                    {item.type !== "search" ? "View" : "Open Link"}</Button>
                              </div>
                           </div>
                        </li>

                     )
                  })
                  }
                  {version === 0 && contentList.type === "quiz" && <>
                     <li class="cards__item">
                        <div class="card">
                           <div class="card__image " style={{ backgroundImage: `url(../images/Exam.jpg)` }}>
                           </div>
                           <div class="card__content">
                              <div class="card__title"> {contentList.title}</div>
                              <p class="card__text">{contentList.otherdesc} </p>
                              {/* <button class="btn btn--block card__btn"  onClick={() =>viewFormative()}>View</button> */}
                              <Button type='default' size='middle' shape='round' onClick={() => viewFormative()}>View</Button>
                           </div>
                        </div>
                     </li>
                  </>
                  }
                  {(version === 2 && lesson.sections.length > 0) ?
                     <LessonViewer data={lesson.sections[currentSectionIdx]?.contents[currentSectionContentIdx]?.data} version={lesson.sectionversion} /> :
                     (version === 2 && <div style={{
                        backgroundColor: "white",
                        border: "1px solid black",
                        height: "calc(100vh - 250px)",
                        width: "100%",
                        margin: "auto",
                        alignContent: "center",
                        textAlign: "center",
                        overflow: "hidden !important",
                        justifyContent: "center"
                     }}>
                        <div
                           style={{
                              width: "auto",
                              height: "auto",
                              margin: "0 auto",
                              position: "relative",
                           }}
                        >
                           <Empty />
                        </div>
                     </div>)
                  }
               </ul>
            </div>
         </div>
      </>
   }

   // const viewFormative = () => {
   //    var firstContent = lessonState.sections[currentSectionIdx]?.contents[currentSectionContentIdx];

   //    var assessment_id = firstContent.otherid
   //    var assignment_id = firstContent.otherid2
   //    var q_type = firstContent.q_type;
   //    let role = Utils.getCurrentUserInfo(Enums.UserInfo.role)

   //    if (role === 'Admin') {

   //       if (q_type === 1)
   //          navigate('/formativeassessment/preview-pdf', { state: { "id": assessment_id, levelid, lessonid, mode } });
   //       else
   //          navigate('/formativeassessment/preview-manual', { state: { "id": assessment_id, levelid, lessonid, mode } });
   //    }
   //    else {
   //       // Student
   //       if (q_type === 1)
   //          navigate('/formativeassessment/answer-pdf', { state: { assessment_id, assignment_id, levelid, lessonid, mode } });
   //       else
   //          navigate('/formativeassessment/answer-manual', { state: { assessment_id, assignment_id, levelid, lessonid, mode } });
   //    }
   // }

   const viewFormative = () => {
      var firstContent = lessonState.sections[currentSectionIdx]?.contents[currentSectionContentIdx];
      var assessment_id = firstContent.otherid;
      var assignment_id = firstContent.otherid2;
      var q_type = firstContent.q_type;
      var user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
      var role = Utils.getCurrentUserInfo(Enums.UserInfo.role)

      if (role === 'Admin') {
         if (q_type === 1) {
            navigate('/formativeassessment/preview-pdf', { state: { id: assessment_id, origin: 1 } });
         }
         else
            navigate('/formativeassessment/preview-manual', { state: { id: assessment_id, origin: 1 } });
      }
      else {
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

         let timestampz = new Date().getTime();

         var ansInfo = {
            // Id: "ans_" + o2o_mode + "_" + uuid(),
            Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + user_id,
            Assessment_id: assessment_id,
            Assignment_id: assignment_id,
            Student_id: user_id,
            User_agent: platform.ua,
            Browser: platform.name,
            Browser_version: platform.version,
            OS_platform: platform.os.family,
            Device: platform.product,
         }

         const addAnsUrl = `${process.env.REACT_APP_API_ASSESSMENT}/addanswer?at=formative`;
         axios.post(addAnsUrl, ansInfo).then(response => {
            // console.log(response);
            if (response.data) {
               //-- This routine is for answering assessment
               const url = `${process.env.REACT_APP_API_ASSESSMENT}/info?id=${assessment_id}&at=formative`;
               axios.get(url).then((response) => {
                  if (response.data) {
                     const data = response.data;

                     var qs = JSON.parse(data.question_sheet);

                     for (let i = 0; i < qs.length; i++) {
                        if (qs[i].type !== 'section') {
                           delete qs[i].correct;
                        }
                     }

                     var assInfo = {
                        Id: data.id,
                        Title: data.title,
                        Term: data.term,
                        File_url: data.file_url,
                        Question_type: data.question_type,
                        Created_by: data.created_by,
                        Date_created: data.date_created,
                        Question_sheet: qs,
                        Instruction: data.instruction
                     };

                     axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/assigninfo?id=${assignment_id}&at=formative`).then((response) => {
                        if (response.data) {
                           var assignInfo = response.data;

                           if (q_type === 1) {
                              navigate('/formativeassessment/answer-pdf', { state: { id: ansInfo.Id, assInfo, assignInfo } });
                           }
                           else {
                              navigate('/formativeassessment/answer-manual', { state: { id: ansInfo.Id, assInfo, assignInfo } });
                           }
                        }
                     }).catch((error) => {
                        console.log(error);
                     });
                  }
               }, (error) => {
                  console.log(error.message);
               });

            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            Modal.error({
               title: 'Formative Assessment',
               content: 'Attempt to answer assessment failed.',
               centered: true
            });
         });
      }
   }


   const _downloadFile = (url, name) => {
      setDownloadStatus(true)
      fetch(url)
         .then(response => {
            response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = name;
               a.click();
               setDownloadStatus(false)
            });
         });
   };


   const _playContent = (type, link) => {
      if (version === 0) {
         switch (type) {
            case 'pdf':
               return <>
                  <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "10px" }}>
                     <Button disabled={downloadStatus} loading={downloadStatus} type="primary" shape="round" icon={<AntDIcons.CloudDownloadOutlined />} onClick={() => _downloadFile(currentS3ContentLink, downloadOfficeFileName)} >
                        {downloadStatus ? "Downloading ..." : "Download"}
                     </Button>
                  </div>
                  <RenderPDFReader url={link} height={"calc(100vh - 300px)"} />
                  {/* <RenderPDFViewer url={link} height={"calc(100vh - 300px)"} showdownload={true} showprint={true} showpagecontrols={true} showfullscreen={true} /> */}
               </>
            case 'msoffice':
               return <>
                  <div>
                     <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "10px" }}>
                        <Button disabled={downloadStatus} loading={downloadStatus} type="primary" shape="round" icon={<AntDIcons.CloudDownloadOutlined />} onClick={() => _downloadFile(currentS3ContentLink, downloadOfficeFileName)} >
                           {downloadStatus ? "Downloading ..." : "Download"}
                        </Button>
                     </div>
                     <div style={{ display: "flex" }}>
                        <Iframe url={link}
                           position="absolute"
                           width="100%"
                           id="frameOffice"
                           height="90%"
                        />
                     </div>
                  </div>
               </>
            case 'videolink': case 'youtube': case 'video':
               return <ReactPlayer
                  controls={true}
                  width='100%'
                  height='100%'
                  url={link.replace(/ /g, '+').replace(/#/g, '%23')}
               />
            case 'image':
               return <Image
                  // width='100%'
                  height='100%'
                  src={link}
               />
            case 'search':
               return <WebsiteContent currentSectionIdx={currentSectionIdx} currentSectionContentIdx={currentSectionContentIdx} linkIndex={currentContentLinkIdx} />
            case 'quiz':
               return <div style={{ textAlign: "center", height: "100%", backgroundColor: "white" }}>
                  <div class="parent">
                     <div class="child1"> <img src='../images/Exam.jpg' style={{ width: "80%", height: "100%" }}></img></div>
                     <div class="child2">
                        <h2 style={{ color: "white" }}>Quiz / Assessment</h2>
                        <div style={{
                           textAlign: "center", height: "calc(100% - 75px)", backgroundColor: "white",
                           display: "flex", justifyContent: "center", alignItems: "center",
                           borderRadius: "20px 0px 0px 20px;"
                        }}>
                           <div>
                              <h4 style={{ color: "black" }}> Description : {quizDesc}</h4>
                              <h6 style={{ color: "black" }}>{subjectDesc}</h6>
                              <h5 style={{ color: "black", paddingBottom: "10px" }}> Effective Date : {effectiveDate}</h5>
                              <Button type="primary" onClick={() => viewFormative()} shape="round" icon={<AntDIcons.SearchOutlined />} size={"large"}>Preview</Button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            default: return <>
               <div style={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
                  <Empty
                     description={
                        <span>
                           File is not recognized by the system.
                        </span>
                     }
                     image="../images/brainee_mascot.png"
                  >
                     <Button disabled={downloadStatus} loading={downloadStatus} type="primary" shape="round" icon={<AntDIcons.CloudDownloadOutlined />} onClick={() => _downloadFile(currentS3ContentLink, downloadOfficeFileName)} >
                        {downloadStatus ? "Downloading ..." : "Download"}
                     </Button>
                  </Empty>
               </div>
            </>
         }
      }
      else {
         return (
            (version === 2 && lesson.sections.length > 0) ? <LessonViewer data={lesson.sections[currentSectionIdx]?.contents[currentSectionContentIdx]?.data} version={lesson.sectionversion} /> :
               (version === 2 && <div style={{
                  backgroundColor: "white",
                  border: "1px solid black",
                  height: "calc(100vh - 250px)",
                  width: "100%",
                  margin: "auto",
                  alignContent: "center",
                  textAlign: "center",
                  overflow: "hidden !important",
                  justifyContent: "center"
               }}>
                  <div
                     style={{
                        width: "auto",
                        height: "auto",
                        margin: "0 auto",
                        position: "relative",
                     }}
                  >
                     <Empty />
                  </div>
               </div>)
         )
      }
   }


   const _playNext = () => {
      var idx = currentContentLinkIdx + 1 < contentCount ? currentContentLinkIdx + 1 : contentCount - 1;

      if (contentList.links[idx].url !== "") {
         var s3FullPathWithOfficeLink = ""
         var s3FullPathName = ""

         if (contentList.links[idx].type === "msoffice") {
            var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
            var s3File = contentList.links[idx].url.split(msOfficelink)
            s3FullPathWithOfficeLink = s3File[1]
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }
         else if (contentList.links[idx].type === "others") {
            s3FullPathWithOfficeLink = contentList.links[idx].url
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }

         setCurrentS3ContentLink(s3FullPathWithOfficeLink)
         setDownloadOfficeFileName(s3FullPathName)
      }
      setCurrentContentLink(contentList.links[idx].url);
      setCurrentContentType(contentList.links[idx].type);
      setCurrentContentLinkIdx(idx);
   };

   const _playPrevious = () => {
      var idx = currentContentLinkIdx - 1 >= 0 ? currentContentLinkIdx - 1 : 0;
      if (contentList.links[idx].url !== "") {
         var s3FullPathWithOfficeLink = ""
         var s3FullPathName = ""

         if (contentList.links[idx].type === "msoffice") {
            var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
            var s3File = contentList.links[idx].url.split(msOfficelink)
            s3FullPathWithOfficeLink = s3File[1]
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }
         else if (contentList.links[idx].type === "others") {
            s3FullPathWithOfficeLink = contentList.links[idx].url
            var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
            s3FullPathName = contentList.links[idx].title + "." + s3FileExt
         }

         setCurrentS3ContentLink(s3FullPathWithOfficeLink)
         setDownloadOfficeFileName(s3FullPathName)
      }
      setCurrentContentLink(contentList.links[idx].url);
      setCurrentContentType(contentList.links[idx].type);
      setCurrentContentLinkIdx(idx);
   };

   const _editLesson = (lessonIdx, topicIdx, type) => {
      setCurrentTopicEditIdx(topicIdx)
      setCurrentLessonEditIdx(lessonIdx)
      if (type !== "quiz") {
         navigate(`/subject/edit/lesson?Id=${lessonid}&lessonIdx=${lessonIdx}&topicIdx=${topicIdx}`);
         // setVisible(true)
      }
      else {
         setVisibleModal(true)

      }
   }

   const refreshLesson = () => {
      setLesson(lessonState)
      if (lessonState.sections.length > 0) {
         var firstContent = lessonState.sections[currentSectionIdx]?.contents[currentSectionContentIdx];
         if (firstContent) {
            if (firstContent.links.length > 0) {
               setCurrentContentLink(firstContent.links[0].url);
               setCurrentContentType(firstContent.links[0].type);
            }
            else if (firstContent.type === "quiz") {
               setCurrentContentLink("");
               setCurrentContentType(firstContent.type);
            }
            else {
               setCurrentContentLink("");
               setCurrentContentType("");
            }
            setContentCount(firstContent.links.length);
            setCurrentContentLinkIdx(0);
            setContentList(firstContent);
         }
         else {
            setCurrentContentLink("");
            setCurrentContentType("");
         }
      }
      else {
         setCurrentContentLink("");
         setCurrentContentType("");
      }

   }

   const _lessonClick = (topicIdx, lessonIdx, type) => {
      const itemIdx = lessonIdx.split('-');
      setCurrentSectionIdx(itemIdx[1])
      setCurrentSectionContentIdx(itemIdx[0])
      var firstContent = lessonState.sections[itemIdx[1]]?.contents[itemIdx[0]];

      var sectionVersion = firstContent?.data ? 2 : 0
      setVersion(sectionVersion)

      if (sectionVersion === 2) return

      if (firstContent.links.length > 0) {

         if (firstContent.links[0].url !== "") {
            var s3FullPathWithOfficeLink = ""
            var s3FullPathName = ""

            if (firstContent.links[0].type === "msoffice") {
               var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
               var s3File = firstContent.links[0].url.split(msOfficelink)
               s3FullPathWithOfficeLink = s3File[1]
               var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
               s3FullPathName = firstContent.links[0].title + "." + s3FileExt
            }
            else if (firstContent.links[0].type === "others") {
               s3FullPathWithOfficeLink = firstContent.links[0].url
               var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
               s3FullPathName = firstContent.links[0].title + "." + s3FileExt
            }

            setCurrentS3ContentLink(s3FullPathWithOfficeLink)
            setDownloadOfficeFileName(s3FullPathName)
         }
         setCurrentContentLink(firstContent.links[0].url);
         setCurrentContentType(firstContent.links[0].type);
      }
      else if (firstContent.type === "quiz") {
         var today = moment(new Date()).format('YYYY-MM-DD');
         var current = false;

         var tmpeffectivedate = firstContent.effectivedate;
         var eDate = ""
         if (tmpeffectivedate.includes("@")) {
            var tmpdatesplit = tmpeffectivedate.split("@");
            var startDate = moment(tmpdatesplit[0], 'YYYY-MM-DD').format('YYYY-MM-DD')
            var endDate = moment(tmpdatesplit[1], 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
            //console.log("Date Start : " + startDate)
            //console.log("Date End : " + endDate)
            //current = today.isBetween(moment(tmpdatesplit[0],'YYYY-MM-DD').format('YYYY-MM-DD'),moment(tmpdatesplit[1],'YYYY-MM-DD').add(1,'d').format("YYYY-MM-DD"), null, '[]') 
            current = (today >= startDate && today <= endDate)
            //console.log("Current : " + current)
            eDate = tmpdatesplit[0] + " - " + tmpdatesplit[1]
         }
         else {
            var effectivedate = moment(firstContent.effectivedate, 'YYYY-MM-DD').format('YYYY-MM-DD')
            eDate = firstContent.effectivedate + " - " + moment(firstContent.effectivedate, 'YYYY-MM-DD').add(1, 'd').format("YYYY-MM-DD")
            // current =  today.isBefore(effectivedate) 
            current = (today <= effectivedate)
         }

         setQuizStatus(current)
         setCurrentContentLink("");
         setCurrentContentType(firstContent.type);
         setEffectiveDate(eDate);
         setSubjectDesc(firstContent.otherdesc);
         setQuizDesc(firstContent.title)
      }
      else {
         setCurrentContentLink("");
         setCurrentContentType("");
      }
      setContentCount(firstContent.links.length);
      setCurrentContentLinkIdx(0);
      setContentList(firstContent);
   }


   const _saveLesson = () => {
      let datearray = lessonState.availabilitydate;
      let dvalue = "-"
      if (datearray !== undefined) {
         let d1 = datearray[0];
         let d2 = datearray[1];
         dvalue = d1 + ' - ' + d2;
      }

      let lessonTmp = {
         LessonId: lessonState.lessonid,
         Title: lessonState.title,
         TeacherName: lessonState.teachername,
         Subject: lessonState.subject,
         Overview: overview !== "" ? overview : lessonState.overview,
         Term: lessonState.term,
         AvailabilityDate: dvalue,
         EducationalLevel: lessonState.educationallevel,
         StudentViewing: lessonState.studentviewing,
         LessonSharing: lessonState.lessonsharing,
         GradeLevel: lessonState.gradelevel,
         SectionList: lessonState.sectionlist,
         LearningType: lessonState.learningtype,
         LearningMode: lessonState.learningmode,
         LearningPlan: lessonState.learningplan,
         Conference: lessonState.conference,
         ConferenceLink: '',
         Type: "",
         SectionVersion: 2,
         Sections: lesson.sections,
         AssignedStudents: lessonState.assignedstudents,
      }
      LessonService.saveStateLesson(lessonTmp).then(response => {
         console.log(response)
         if (response.data) {

            //  openNotification('bottomRight');
            /* 
             Modal.success({
                content: 'Lesson was saved.',
                centered: true
             }); */
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Schedule',
            content: 'Lesson creation failed.',
            centered: true
         });
      });
      //setVisible(false)
      setVisibleModal(false)
   }


   const LearningPlanNotAvailable = () => {
      Modal.info({
         title: 'Lesson',
         content: 'Learning Plan not available.',
         centered: true,
      });
   }

   const OkClickEditLesson = () => {
      _saveLesson()
      setVisible(false)
      setVisibleModal(false)
   }


   const autoPlayClick = () => {
      if (lessonState.sections.length > 0) {
         setCurrentContentLinkIdx(0);
         if (contentList.links.length > 0) {
            if (contentList.links[0].url !== "") {
               var s3FullPathWithOfficeLink = ""
               var s3FullPathName = ""

               if (contentList.links[0].type === "msoffice") {
                  var msOfficelink = process.env.REACT_APP_MSOFFICE_VIEWER
                  var s3File = contentList.links[0].url.split(msOfficelink)
                  s3FullPathWithOfficeLink = s3File[1]
                  var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                  s3FullPathName = contentList.links[0].title + "." + s3FileExt
               }
               else if (contentList.links[0].type === "others") {
                  s3FullPathWithOfficeLink = contentList.links[0].url
                  var s3FileExt = s3FullPathWithOfficeLink.split('.').pop()
                  s3FullPathName = contentList.links[0].title + "." + s3FileExt
               }

               setCurrentS3ContentLink(s3FullPathWithOfficeLink)
               setDownloadOfficeFileName(s3FullPathName)
            }

            setCurrentContentLink(contentList.links[0].url);
            setCurrentContentType(contentList.links[0].type);
         }
         else if (contentList.type === "quiz") {
            setCurrentContentLink("");
            setCurrentContentType(contentList.type);
         }
         setCurrentContentLinkIdx(0);
         setViewState(1)
      }
   }

   const _setToggle = () => {
      setToggleSideMenu(!toggleSideMenu);
   }

   const onChangeLessonView = ({ target: { value } }) => {
      setLessonButton(value);

      if (value === '0')
         setViewState(0);
      else
         autoPlayClick();
   };

   const upload_props = {
      onRemove: file => {
         setUploadFileList([]);
      },

      beforeUpload: file => {
         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
         const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
         const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         const isPPT = file.type === 'application/vnd.ms-powerpoint';
         const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
         const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
         const isPDF = file.type === 'application/pdf';

         const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX;

         if (!fileValid) {
            setUploadFileList([]);

            Modal.error({
               title: 'Lesson',
               content: 'You are not allowed to upload that file type.',
               centered: true
            });

            return fileValid || Upload.LIST_IGNORE;

         } else {
            handleUpload(file);
         }

         // return false;
      },

      // progress: {
      //    strokeColor: {
      //       '0%': '#108ee9',
      //       '100%': '#87d068',
      //    },
      //    strokeWidth: 3,
      //    format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
      // },
   };

   const option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded / total) * 100);
         setPercentage(p);
         // console.log(`${percentage} %`);
      }
   }

   const handleUpload = (file) => {
      // const key = 'updatable';
      // message.loading({ content: 'Uploading your document...', key, duration: 0 });

      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadlearningplan?s3path=${process.env.REACT_APP_S3_LEARNING_PLAN_PATH}`;

      // const headers = { 'Content-Type': 'multipart/form-data' };
      axios.post(url, formData, option).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            setIsUploading(false);
            setLearningPlan(data);

            axios.post(`${process.env.REACT_APP_API_LESSON}/updateLessonProperty?lessonid=${lessonid}&field=learningplan&fieldvalue=${data}`).then((response) => {
               if (response.data) {
                  // message.success({ content: 'Upload successful', key });

                  Modal.success({
                     title: 'Lesson',
                     content: 'File upload successful.',
                     centered: true
                  });
               }
            }, (error) => {
               console.log(error.message);
               // message.success({ content: 'Upload failed', key });
               Modal.error({
                  title: 'Lesson',
                  content: 'File upload failed.',
                  centered: true,
               });
            });
         }
      }, (error) => {
         console.log(error.message);
         // message.success({ content: 'Upload failed', key });
         Modal.error({
            title: 'Lesson',
            content: 'File upload failed.',
            centered: true
         });
         setIsUploading(false);
      });
   }

   const showLearningPlan = () => {
      (learningPlan !== '' ? setVisibleLearningPlan(true) : LearningPlanNotAvailable());
   }

   const fullScreen = () => {
      setShowFullScreen(true)
   }

   useEffect(() => {
      if (showFullScreen)
         fullScreenHandle.enter()
   }, [showFullScreen]);

   const escapeHandler = (e) => {
      if (!document?.webkitIsFullScreen && !document?.mozFullScreen && !document?.msFullscreenElement)
         setShowFullScreen(false)
   }

   useEffect(() => {
      document.addEventListener('fullscreenchange', escapeHandler, false);
      document.addEventListener('mozfullscreenchange', escapeHandler, false);
      document.addEventListener('MSFullscreenChange', escapeHandler, false);
      document.addEventListener('webkitfullscreenchange', escapeHandler, false);
   }, []);


   return (<>
      {
         role !== "Student" &&
         <ChatGPTFB />
      }
      {toggleSideMenu && <div className='button-side-menu' onClick={() => setToggleSideMenu(false)}>
         <div style={{ psingTop: "3px", paddingRight: "10px" }}><AntDIcons.ArrowLeftOutlined /></div>
         <div>Topic Content</div>
      </div>}
      {lesson && <div className={toggleSideMenu ? "dashboard-area-all" : "dashboard-area"}>
         <div className="dashboard-item">
            <div className="dashboard-item-inner">
               {/* <ul>
                  <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                  <li><span>/</span></li>
                  <li className="active">Brainee LMS</li>
                  <li><span>/</span></li>
                  <li><a href="#" onClick={() => navigate('/subject')}>Lesson</a></li>
                  <li><span>/</span></li>
                  <li className="active" >{props.mode === "view" ? "View" : "Edit"}</li>
               </ul> */}
               <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                  <Breadcrumb.Item>
                     {/* <AntDIcons.HomeOutlined /> */}
                     <Link to="/home">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={type !== "resources" ? "/subject" : "/brainee-resources"}>{type !== "resources" ? "Lesson" : "Brainee Resources"}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{props.mode === "view" ? "View" : "Edit"}</Breadcrumb.Item>
               </Breadcrumb>
            </div>
            <Divider />
            <div className="dashboard-item-inner">
               <div className="container">
                  <div className="row justify-between">
                     <div className="col-12">
                        <div className="LessonTitle"><Text elipsis>{lesson.title}</Text></div>
                     </div>

                  </div>
               </div>
               <div className="container">
                  <div className="row justify-content-center">
                     <div className="col-12">
                        <div className="SubLessonTitle">{lesson.teachername !== "" ? lesson.teachername + "," : ""} {lesson.subject}</div>
                     </div>
                     <div className="col-12" >
                        <div className='LessonButtons'>
                           {/* <button className='ButtonMiddle' onClick={() => setVisibleAI(true)} ><ApartmentOutlined /></button>   */}
                           <Space direction='horizontal'>
                              {/* <Upload {...upload_props} showUploadList={false} accept=".png,.jpg,.bmp" style={{ width: "100%", display: 'block !important' }}>
                                 <Tooltip placement="left" title='Upload My Answer in Image Format'>
                                    <Button className='button-shadow' type="primary" size="middle" shape='round' block>
                                       <Text ellipsis style={{ color: "#fff" }}><AntDIcons.UploadOutlined />&nbsp;Upload My Answer</Text>
                                    </Button>
                                 </Tooltip>
                              </Upload> */}

                              <ButtonGroup shape='round'>
                                 <Tooltip placement="top" title='Full Screen'>
                                    <Button type='default' disabled={isMobile} shape='round' onClick={() => fullScreen()}><AntDIcons.FullscreenOutlined /> Full Screen</Button>
                                 </Tooltip>
                                 {
                                    role !== 'Student' &&
                                    <>
                                       <Tooltip placement="top" title='Show Learning Plan'>
                                          <Button type='default' shape='round' onClick={() => showLearningPlan()}><AntDIcons.CarryOutOutlined /> Learning Plan</Button>
                                       </Tooltip>
                                       <Tooltip placement="top" title='Upload Learning Plan'>
                                          <Upload
                                             {...upload_props}
                                             showUploadList={false}
                                             accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,.png,.jpg,.pdf,.ppsx"
                                          >
                                             <Button type='default' shape='round' style={{ borderTopRightRadius: '7px', borderBottomRightRadius: '7px' }}>
                                                <AntDIcons.CloudUploadOutlined />
                                             </Button>
                                          </Upload>
                                       </Tooltip>
                                    </>
                                 }

                              </ButtonGroup>

                              {version === 0 && <Radio.Group
                                 options={
                                    [
                                       {
                                          label: <AntDIcons.AppstoreOutlined />,
                                          value: '0',
                                       },
                                       {
                                          label: <AntDIcons.PlayCircleOutlined />,
                                          value: '1',
                                       },
                                    ]
                                 }
                                 onChange={onChangeLessonView}
                                 value={lessonButton}
                                 optionType="button"
                                 buttonStyle="solid"
                              />}
                              {
                                 (viewState === 1 && version === 0) &&
                                 <>
                                    <Radio.Group>
                                       <Radio.Button type='primary' shape='round' onClick={() => _playPrevious(0)}><AntDIcons.CaretLeftOutlined /></Radio.Button>
                                       <Radio.Button type='primary' shape='round' onClick={() => _playNext()}><AntDIcons.CaretRightOutlined /></Radio.Button>
                                    </Radio.Group>
                                 </>
                              }
                           </Space>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="dashboard-item-inner3">
            {viewState === 1 ? <div className="container">
               <div className="row justify-content-center">
                  <div className="col-12">
                     <div className='LessonProper'>
                        {_playContent(currentContentType, currentContentLink)}
                     </div>
                  </div>
               </div>
            </div> :
               <>
                  {/* <div style={{ textAlign: "center" }}><h5>List of Resource(s)</h5></div> */}
                  <div className='LessonProper'>
                     <SimpleBarReact style={{ height: "100%" }}>{_tileView()}</SimpleBarReact>
                  </div>
               </>
            }
         </div>
         <div className="dashboard-item-inner3">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-12">
                     <div className='small-screen-tab'>
                        <Tabs defaultActiveKey="1">
                           <TabPane tab="Lesson Section" key="1">
                              {(!toggleSideMenu && lesson) &&
                                 <div className="container">
                                    <div style={{ paddingTop: "15px" }}>
                                       <TopicSection topics={lesson.sections} editLesson={_editLesson} lessonClick={_lessonClick} toggle={_setToggle} mode={mode} saveLesson={_saveLesson} refreshLesson={refreshLesson} />
                                    </div>
                                 </div>}
                           </TabPane>
                           <TabPane tab="Overview" key="2">
                              <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: "12px", marginBottom: "5px" }}><Button type="primary" shape='round' onClick={() => _saveLesson()}>Save Overview</Button></div>
                              <div style={{ position: "relative", height: "80%", padding: "5px" }}>
                                 {/* <Editor
                                    apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    onEditorChange={(content) => setOverview(content)}
                                    initialValue={lesson.overview}
                                    init={{
                                       menubar: false,
                                       height: 220,
                                       plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount table emoticons'
                                       ],
                                       toolbar: 'undo redo | formatselect | ' +
                                          'bold italic backcolor | alignleft aligncenter ' +
                                          'alignright alignjustify | bullist numlist outdent indent | ' +
                                          'removeformat | table | fullscreen | help',
                                       content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    setup
                                 /> */}
                                 <Wysiwyg2
                                    changeContentCallback={(e) => setOverview(e)}
                                    content={lesson.overview}
                                    height={220}
                                    contentHeight={180}
                                 />
                              </div>
                           </TabPane>
                        </Tabs>
                     </div>
                     <div className='normal-screen-tab'>
                        <Tabs defaultActiveKey="1">
                           <TabPane tab="Overview" key="1">
                              <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: "12px", marginBottom: '10px' }}>
                                 <Button type="primary" shape='round' onClick={() => _saveLesson()}>Save Overview</Button>
                              </div>
                              <div style={{ position: "relative", height: "80%", marginBottom: '10px' }}>
                                 {/* <Editor
                                    apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    onEditorChange={(content) => setOverview(content)}
                                    initialValue={lesson.overview}
                                    init={{
                                       menubar: false,
                                       height: 220,
                                       plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount table emoticons'
                                       ],
                                       toolbar: 'undo redo | formatselect | ' +
                                          'bold italic backcolor | alignleft aligncenter ' +
                                          'alignright alignjustify | bullist numlist outdent indent | ' +
                                          'removeformat | table | fullscreen | help',
                                       content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    setup
                                 /> */}
                                 <Wysiwyg2
                                    changeContentCallback={(e) => setOverview(e)}
                                    content={lesson.overview}
                                    height={220}
                                    contentHeight={180}
                                 />
                              </div>
                           </TabPane>
                        </Tabs>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div >}
      {
         (!toggleSideMenu && lesson) && <div className="notice-area-topic notice-area">

            <div className="notice-item">
               <div className="container">
                  <div style={{ paddingTop: "5px" }}>
                     <TopicSection topics={lesson.sections} editLesson={_editLesson} lessonClick={_lessonClick} toggle={_setToggle} mode={mode} saveLesson={_saveLesson} refreshLesson={refreshLesson} />
                  </div>
               </div>
            </div>
         </div>
      }

      {(lesson && showFullScreen) && <FullScreen handle={fullScreenHandle}>
         <FullScreenViewer topicIdx={currentSectionContentIdx} lessonIdx={currentSectionIdx} sections={lesson} exit={fullscreenExit} />
      </FullScreen>}

      <Modal
         key="Modal-Edit-Mode-Quiz"
         title="Edit Quiz/Assignment"
         destroyOnClose={true}
         centered
         open={visibleModal}
         okButtonProps={{ style: { display: 'none' } }}
         onCancel={() => setVisibleModal(false)}
      >
         <EditQuiz topicIdx={currentTopicEditIdx} lessonIdx={currentLessonEditIdx} refreshLesson={refreshLesson} saveLesson={_saveLesson} />
      </Modal>

      <Modal
         key="Modal-Edit-Mode"
         title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Add/Edit Resources</div>}
         destroyOnClose={true}
         centered
         open={visible}
         onOk={() => OkClickEditLesson()}
         onCancel={() => setVisible(false)}
         width='70%'
      >
         <EditLesson topicIdx={currentTopicEditIdx} lessonIdx={currentLessonEditIdx} refreshLesson={refreshLesson} saveLesson={_saveLesson} />
      </Modal>

      <Modal
         key="Modal-View-Mode"
         title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> View Lesson</div>}
         destroyOnClose={true}
         centered
         open={visibleTile}
         onOk={() => setVisibleTile(false)}
         onCancel={() => setVisibleTile(false)}
         width='80%'
         // height='80%'
         okButtonProps={{
            style: {
               display: "none",
            },
         }}
      >
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-12">
                  <div className='LessonProper'> {_playContent(currentContentType, currentContentLink)}</div>
               </div>
            </div>
         </div>
      </Modal>

      <Modal
         key="Modal-AI"
         // title={<Image src='../images/BraineeGPT.png' preview={false} height="30px" />}
         title="Brainee Your AI Companion"
         destroyOnClose={true}
         centered
         open={visibleAI}
         okButtonProps={{ style: { display: 'none' } }}
         onCancel={() => setVisibleAI(false)}
         cancelText="Close"
         width={"50%"}
      >
         <ChatGPT />
      </Modal>

      <Modal
         key="modal-learning-plan"
         title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Learning Plan</div>}
         destroyOnClose={true}
         centered
         open={visibleLearningPlan}
         onOk={() => setVisibleLearningPlan(false)}
         okText={"Close"}
         okButtonProps={{ shape: "round" }}
         cancelButtonProps={{ shape: "round", style: { display: 'none' } }}
         width='75%'
      >
         <div className='student_list_container'>
            <DocumentViewer
               document={process.env.REACT_APP_S3_LEARNING_PLAN_PATH + '/' + learningPlan}
               pdfshowdownload={true}
               pdfshowprint={true}
               ismodal={true}
               dvheight={"calc(100vh - 220px)"}
            />
         </div>
      </Modal>

      <Modal
         key="modal-upload"
         title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Upload File</div>}
         destroyOnClose={true}
         centered
         open={isUploading}
         okButtonProps={{ style: { display: 'none' } }}
         cancelButtonProps={{ style: { display: 'none' } }}
      >
         <span>Uploading...</span>
         <Progress
            percent={percentage}
            status="active"
            strokeColor={{
               '0%': '#5A98D2',
               '100%': '#70D0D4',
            }}
         />
      </Modal>
   </>)
}
