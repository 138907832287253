/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Spin, Empty, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row,
   Col, Tag, notification, Popconfirm, Modal, Radio, Form, Breadcrumb, message
} from 'antd';
import {
   FormOutlined, EditOutlined, DeleteOutlined, CopyOutlined, EyeOutlined, ReadOutlined, StopOutlined,
   ShareAltOutlined, TeamOutlined, UsergroupAddOutlined, CaretRightOutlined, StopFilled, CaretRightFilled,
   PlusOutlined
} from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import uuid from 'react-uuid';
// import SimpleBarReact from "simplebar-react";
import { useDispatch } from 'react-redux';
import './SubjectList.scss';
import { set_lessonrec, reset_lesson, post_lookup, set_lesson } from '../../actions';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import ZoomService from '../../services/ZoomService'
// import SkeletonTable from '../../components/lesson/SkeletonTable';
// import LessonService from '../../services/LessonService';
import qs from 'qs';
// import { capitalize } from 'lodash';
import './SubjectCard.scss'

const CryptoJS = require("crypto-js");

const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;

export function SubjectGroupings() {
   const [form] = Form.useForm();
   const location = useLocation();
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   // const [createView, setCreateView] = useState(false);
   const [editView, setEditView] = useState(false);
   const [viewLesson, setViewLesson] = useState(false);
   const [key, setKey] = useState(null);
   const [levelId, setLevelId] = useState(null);
   const [lessonData, setLessonData] = useState([])

   const [data, setData] = useState([])
   const [dataSubject, setDataSubject] = useState([])
   const [dataContainer, setDataContainer] = useState([])
   const [assOptions, setAssOptions] = useState(null);
   const [assTitle, setAssTitle] = useState('');
   const [assignVisible, setAssignVisible] = useState(false);
   // const [hidevalue,setHidevalue]=useState(false)
   const [assessmentid, setAssessmentid] = useState()
   // const [editLessonView, setEditLessonView] = useState(false);
   // const [currentUsername, setCurrentUsername]= useState('');
   const [currentRole, setCurrentRole] = useState('');
   const [isAdmin, setIsAdmin] = useState(false)
   const [loading, setLoading] = useState(false);
   const [activeTab, setActiveTab] = useState("1");
   const [defaultTab, setDefaultTab] = useState("1");
   const [tabname, setTabName] = useState("")
   const [publishModal, setPublishModal] = useState(false)
   const [shareModal, setShareModal] = useState(false)
   // const [initiatorModal,setInitiatorModal] = useState(false);
   // const [initiatorStatus,setInitiatorStatus] = useState('')
   const [studentviewing, setStudentViewing] = useState('')
   const [lessonsharing, setLessonSharing] = useState('')
   const [lessonId, setLessonId] = useState('')
   const [user_id, setUser_Id] = useState(0);
   // const [importantMessage,setImportantMessage]=useState('')
   // const [disableStart,setDisableStart]=useState(false)
   const [sharedList, setSharedList] = useState([])

   // const [zoomPickerModal, setZoomPickerModal] = useState(false);
   // const [pickerZoomAccount, setPickerZoomAccount] = useState('');
   const [zoomPickerList, setZoomPickerList] = useState([]);
   const [hasClassStarted, setHasClassStarted] = useState(false);
   const [viewType, setViewType] = useState(0);
   const [subjectSelected, setSubjectSelected] = useState('')

   const [zoomPickerOptions, setZoomPickerOptions] = useState({
      Modal: false,
      List: []
   });

   const [zoomObserveOptions, setZoomObserveOptions] = useState({
      Modal: false,
      List: []
   });

   const [userList, setUserList] = useState([]);
   const [lessonAttendanceList, setLessonAttendanceList] = useState([]);
   const [showLessonAttendanceModal, setShowLessonAttendanceModal] = useState(false);

   let columnStyle = {
      float: "left",
      width: "60%",
      padding: "5px",
      position: "relative"
   };
   const [column] = useState(columnStyle);

   const icon_style = { color: '#4E89FF', cursor: 'pointer' };

   const dispatch = useDispatch();

   const user_full_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const user_other_data = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));
   const google_meet_link = user_other_data.GoogleMeet;
   const neukol_user_id = user_other_data.NeukolUserId;
   const g_zoom_account = user_other_data.ZoomAccount;
   const g_email = user_other_data.Email;

   const g_user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const aTab = location.state !== null ? (location.state.activetab) : (role === "Student" ? "3" : "1");



   useEffect(() => {
      // setLoading(true);
      // message.info("Start")
      let url = `${process.env.REACT_APP_API_ZOOM}/zoompickerlist`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            setZoomPickerList(data);
         }
         // message.info("Done Zoom")
      }, (error) => {
         setLoading(false);
         console.log(error.message);
         // message.info("Error in Zoom API")
      });


      if (Utils.getCurrentUserInfo(Enums.UserInfo.role) === "Admin") {
         url = `${process.env.REACT_APP_API_LESSON}/listAllStaff`;
         axios.get(url).then((response) => {
            if (response.data) {
               let data = response.data;
               var users = data.filter((d) => d.id !== Number(g_user_id)).sort((a, b) => {
                  var aVal = a["first_Name"];
                  var bVal = b["first_Name"];
                  switch (typeof aVal) {
                     case 'string':
                        return aVal.localeCompare(bVal);
                     case 'number':
                        return aVal - bVal;
                     default:
                        {
                           setLoading(false);
                           // message.error("Error in listAllStaff API")
                           notification.error({
                              message: `Error Loading list (1)`,
                              description: 'Error Loading list...',
                           });
                           //throw new Error("Unsupported value to sort by");
                        }
                  }
               });
               // message.info("Done List")
               var final = data.filter((d) => d.id === Number(g_user_id));
               Array.prototype.push.apply(final, users);
               setUserList(final)
            }
            // else
            // {
            //    message.error("Error in listAllStaff API")
            // }
         }, (error) => {
            setLoading(false);
            console.log(error.message);
         });
      }

      try {
         var role = Utils.getCurrentUserInfo(Enums.UserInfo.role)
         setIsAdmin(role === "Admin" ? true : false)
         setUser_Id(g_user_id);
         setCurrentRole(role);

         // message.info("Done getCurrentUserInfo")
         // setActiveTab((role === "Student" ? "3" : aTab));
         // setDefaultTab((role === "Student" ? "3" : aTab));
         setActiveTab(aTab);
         setDefaultTab(aTab);
         setLoading(false);
      } catch (error) {
         setLoading(false);
         // message.error("Error in getCurrentUserInfo API")
         notification.error({
            message: `Error Loading list (2)`,
            description: 'Error Loading list...',
         });
      }
      setLoading(false);
      //refreshList(aTab);
   }, []);

   useEffect(() => {
      if (Number(user_id) > 0) {
         // message.info("User ID Ok")
         refreshList(activeTab)
      }
      // else
      // {
      //    message.error("User ID is zero")
      // }
   }, [user_id]);

   // function sortByIdThenName(a, b) {
   //    const n = a.listId - b.listId;
   //    // sort by listId
   //    if (n !== 0) {
   //    return n;
   //    }
   //    // if listId is equal then sort by name
   //    return a.first_Name.localeCompare(b.first_Name);
   // }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'staffid',
         key: 'staffid',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'att_status',
         key: 'att_status',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         // ellipsis: {
         //    showTitle: false,
         // },
         render: payload => {
            return <>
               <Tooltip placement="topLeft" title={payload.title}>
                  <div style={{ width: "100%", cursor: "default" }}>
                     <b><Text>{payload.title}</Text></b>
                     <p style={{ marginBottom: '0px' }}>{payload.availability}</p>
                  </div>
               </Tooltip>
            </>
         },
         width: '23%',
         responsive: ["sm", "xs"]
      },
      {
         title: 'Subject',
         dataIndex: 'subject',
         key: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         // ellipsis: {
         //    showTitle: false
         // },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '10%',
         responsive: ["sm", "xs"]
      },
      {
         title: 'Term',
         dataIndex: 'term',
         key: 'term',
         sorter: (a, b) => a.term - b.term,
         ...getColumnSearchProps('term'),
         width: '5%',
         responsive: ["sm", "xs"]
      },
      {
         title: 'Grade/Section',
         key: 'grade',
         sorter: (a, b) => { return a.grade.localeCompare(b.grade) },
         ...getColumnSearchProps('grade'),
         render: (payload) => {
            return <>
               <div style={{ width: "100%", cursor: "default" }}>
                  <b><Text>{payload.grade}</Text></b>
                  <p style={{ marginBottom: '0px' }}>{payload.section}</p>
               </div>
            </>
         },
         width: '10%',
         responsive: ["sm", "xs"]
      },

      {
         title: 'Type',
         key: 'type',
         dataIndex: 'type',
         sorter: (a, b) => { return a.type.localeCompare(b.type) },
         ...getColumnSearchProps('type'),
         width: '8%',
         responsive: ["sm", "xs"]
      },
      {
         title: 'Teacher',
         key: 'teacher_name',
         dataIndex: 'teacher_name',
         sorter: (a, b) => { return a.teacher_name.localeCompare(b.teacher_name) },
         ...getColumnSearchProps('teacher_name'),
         width: '15%',
         responsive: ["sm", "xs"],
         hidden: role === 'Student' ? false : (tabname === 'shared' ? false : true),
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <span>
               <Space size="small">
                  {
                     (((role === 'Teacher' || role === 'Co-Teacher') && payload.staffid.toString() === g_user_id) || isAdmin)
                        ?
                        <>
                           <Tooltip placement="bottom" title='Add/Edit Topic Content'>
                              <FormOutlined style={{ cursor: 'pointer' }} onClick={() => handleEditTopic(payload.key, payload.level)} />
                           </Tooltip>

                           <Tooltip placement="bottom" title='Edit Lesson Info'>
                              <EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditLesson(payload.key, payload)} />
                           </Tooltip>

                           <Tooltip placement="bottom" title='Remove'>
                              <Popconfirm
                                 title="Are you sure to delete this Lesson?"
                                 onConfirm={() => handleDeleteLesson(payload.key, payload)}
                                 // onCancel={cancel}
                                 okText="Yes"
                                 cancelText="No">
                                 <DeleteOutlined style={{ cursor: 'pointer' }} />
                              </Popconfirm>
                           </Tooltip>
                           <Tooltip placement="bottom" title='Duplicate'>
                              <Popconfirm
                                 title="Are you sure to duplicate this Lesson?"
                                 onConfirm={() => handleDuplicateLesson(payload.key, payload)}
                                 okText="Yes"
                                 cancelText="No">
                                 <CopyOutlined style={{ cursor: 'pointer' }} />
                              </Popconfirm>
                           </Tooltip>
                        </>
                        :
                        <></>
                  }

                  {/* {
                     (tabname === "shared")
                        ?
                        <Tooltip placement="bottom" title='Duplicate'>
                           <Popconfirm
                              title="Are you sure to duplicate this Lesson?"
                              onConfirm={() => handleDuplicateLesson(payload.key, payload)}
                              okText="Yes"
                              cancelText="No">
                              <CopyOutlined style={{ cursor: 'pointer' }} />
                           </Popconfirm>
                        </Tooltip>
                        : <></>
                  } */}

                  <Tooltip placement="bottom" title='View'><EyeOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewLesson(payload.key, 'preview', payload.level)} /></Tooltip>

                  {
                     (tabname === "past" && (role === 'Admin' || role === 'Teacher' || role === 'Co-Teacher'))
                        ?
                        <Tooltip placement="bottom" title='Publish/UnPublish'>
                           {
                              payload.studentviewing === 'allowed'
                                 ?
                                 <ReadOutlined style={{ color: "#4E89FF", cursor: 'pointer' }} onClick={() => handlePublish(payload.key, payload)} />
                                 :
                                 <ReadOutlined style={{ cursor: 'pointer' }} onClick={() => handlePublish(payload.key, payload)} />
                           }


                        </Tooltip>
                        :
                        <></>
                  }
                  {
                     ((tabname === "past" && (role === 'Admin' || role === 'Teacher' || role === 'Co-Teacher')) && ((payload.att_status === null || payload.att_status === 'start') && (payload.type === "online" || payload.type === "hyflex")))
                        ?
                        <>
                           <Tooltip placement="bottom" title='End Online Class'>
                              <StopOutlined style={{ cursor: 'pointer' }} onClick={() => handleStartLesson2(payload.key, 'end', payload.type, payload.level)} />
                           </Tooltip>
                        </>
                        :
                        <></>
                  }
                  {
                     ((tabname === "master") && payload.staffid.toString() === g_user_id)
                        ?
                        <>

                           <Tooltip placement="bottom" title='Share/UnShare'>
                              {
                                 payload.lessonsharing === 'shared'
                                    ?
                                    <ShareAltOutlined style={{ color: "#4E89FF", cursor: 'pointer' }} onClick={() => handleShare(payload.key, payload)} />
                                    :
                                    <ShareAltOutlined style={{ cursor: 'pointer' }} onClick={() => handleShare(payload.key, payload)} />
                              }

                           </Tooltip>
                        </>

                        :
                        <>

                        </>
                  }

                  {
                     (role !== 'Student' && (tabname === "past" || tabname === 'current'))
                        ?
                        <Tooltip placement="left" title='Show students who viewed the lesson'>
                           {
                              <TeamOutlined style={{ color: "#4E89FF", cursor: 'pointer' }} onClick={() => handleShowViewed(payload.key)} />
                           }
                        </Tooltip>
                        :
                        <></>
                  }

                  {
                     (tabname === "current" && role === 'Student' && payload.type === 'online')
                        ?
                        // <Tooltip placement="bottom" title='Join Lesson'>
                        //    <UsergroupAddOutlined style={{ cursor: 'pointer' }}   onClick={() => handleJoinLesson(payload.key, payload.level)}/>
                        // </Tooltip>

                        // <Tooltip placement="bottom" title='Enter Online Class'>
                        //    <Button
                        //       type="primary"
                        //       size='small'
                        //       shape='circle'
                        //       onClick={() => handleJoinLesson(payload.key, payload.level)}
                        //    >
                        //       {/* Join Class */}
                        //       <UsergroupAddOutlined style={{ cursor: 'pointer', fontSize: '14px', color: '#fff' }} />
                        //    </Button>
                        // </Tooltip>
                        <Tooltip placement="bottom" title='Join Online Class'>
                           <Popconfirm
                              title="Are you sure you want to enter the online class?"
                              onConfirm={() => handleJoinLesson(payload.key, payload.level)}
                              okText="Yes"
                              cancelText="No"
                           >
                              {
                                 payload.conference === 'zoom' &&
                                 <img alt='zoom' style={{ cursor: 'pointer', borderRadius: '7px' }} height={28} src={"../images/zoom_logo.png"}></img>
                              }
                              {
                                 payload.conference === 'googlemeet' &&
                                 <img alt='zoom' style={{ cursor: 'pointer', borderRadius: '7px' }} height={28} src={"../images/google_meet_logo.png"}></img>
                              }
                              {
                                 (payload.conference !== 'zoom' && payload.conference !== 'googlemeet') &&
                                 <Button
                                    type="primary"
                                    size='small'
                                    shape='circle'
                                 >
                                    <CaretRightFilled style={{ cursor: 'pointer', fontSize: '14px', color: '#fff' }} />
                                 </Button>
                              }
                           </Popconfirm>
                        </Tooltip>
                        :
                        <></>
                  }

                  {
                     (tabname === "current" && (isAdmin || role === 'Teacher' || role === 'Co-Teacher') && (payload.type === "online" || payload.type === "hyflex"))
                        ?
                        // <Tooltip placement="bottom" title='Start/Stop Class'>
                        <>
                           <Tooltip placement="bottom" title='Enter Online Class'>
                              <Popconfirm
                                 title="Are you sure you want to enter the online class?"
                                 onConfirm={() => handleStartLesson2(payload.key, 'start', payload.type, payload.level, payload.section)}
                                 okText="Yes"
                                 cancelText="No"
                              >
                                 {
                                    payload.conference === 'zoom' &&
                                    <img alt='zoom' style={{ cursor: 'pointer', borderRadius: '7px' }} height={28} src={"../images/zoom_logo.png"}></img>
                                 }
                                 {
                                    payload.conference === 'googlemeet' &&
                                    <img alt='zoom' style={{ cursor: 'pointer', borderRadius: '7px' }} height={28} src={"../images/google_meet_logo.png"}></img>
                                 }
                                 {
                                    (payload.conference !== 'zoom' && payload.conference !== 'googlemeet') &&
                                    <Button
                                       type="primary"
                                       size='small'
                                       shape='circle'
                                    >
                                       <CaretRightFilled style={{ cursor: 'pointer', fontSize: '14px', color: '#fff' }} />
                                    </Button>
                                 }
                              </Popconfirm>
                           </Tooltip>
                        </>
                        : <></>
                  }

                  {/* <Tooltip placement="bottom" title='Join Lesson'>
                  <ForwardOutlined style={{ cursor: 'pointer' }}   onClick={() => openConference(payload.key)}/>
               </Tooltip> */}
                  {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> */}
               </Space>
            </span>
         },
         width: '15%',
         responsive: ["sm", "xs"]
      },
   ].filter(item => !item.hidden);

   const getMasterList = () => {
      try {
         if (Number(user_id) > 0) {
            let root = `${process.env.REACT_APP_LMS_API}api/Lesson/listEntityLessonRecs`
            let url = root + `?userid=${user_id}&role=${role}`
            //console.log(url)

            console.log('loading');
            setLoading(true);
            setTabName("master");
            axios.get(url).then((response) => {
               //  console.log(response);

               if (response.data) {
                  const data = response.data;
                  // message.info("GetMasterList Done(0)" + data[0]["subject"])
                  // message.info("GetMasterList Done (1)")
                  //     var result0 = Object.groupBy(data, ({ subject }) => subject);
                  //    const result1 = data.reduce(
                  //       (entryMap, e) => entryMap.set(e.subject, [...entryMap.get(e.subject)||[], e]),
                  //       new Map()
                  //   );

                  var result = data.reduce(function (r, a) {
                     r[a.subject] = r[a.subject] || [];
                     r[a.subject].push(a);
                     return r;
                  }, Object.create(null));

                  // message.info("GetMasterList Done (2)")
                  // result.sort()
                  setDataSubject(result)
                  // message.info("GetMasterList Done (3)")
                  setData(data);
                  // message.info("GetMasterList Done (4)")
                  setDataContainer(response.data);
                  // message.info("GetMasterList Done")
                  setLoading(false);
               }
               else {
                  message.error("GetMasterList Error")
               }
            }, (error) => {
               console.log(error.message);
               setLoading(false)
               console.log('stop loading')
               message.error("GetMasterList Error : " + error.message)
            });
         }
      } catch (error) {
         message.error("GetMasterList Done")
      }

   }

   const getSharedList = () => {
      if (Number(user_id) > 0) {
         let root = `${process.env.REACT_APP_LMS_API}api/Lesson/listEntitySharedLessonRecs`
         let url = root + `?userid=${user_id}&role=${role}`
         console.log(url)

         console.log('loading')
         setLoading(true)
         setTabName("shared")
         axios.get(url).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               // var result = Object.groupBy(data, ({ subject }) => subject);
               var result = data.reduce(function (r, a) {
                  r[a.subject] = r[a.subject] || [];
                  r[a.subject].push(a);
                  return r;
               }, Object.create(null));
               // result.sort()
               setDataSubject(result)
               setData(data);
               setDataContainer(response.data);
               setLoading(false);
            }
         }, (error) => {
            console.log(error.message);
            setLoading(false)
            console.log('stop loading')
         });
      }
   }

   const getCurrentList = () => {
      if (Number(user_id) > 0) {
         let root = `${process.env.REACT_APP_LMS_API}api/Lesson/listEntityLessonRecsbyTime`
         // let userstr = user.toString();
         let url = root + `?userid=${user_id}&role=${role}&timeline=1`
         console.log(url)

         console.log('loading')
         setLoading(true)
         setTabName("current")
         axios.get(url).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;

               //--Check if there is class started 
               let has_class_started = data.filter(item => item.att_status === 'start');
               if (has_class_started.length > 0)
                  // setHasClassStarted(true);
                  setHasClassStarted(false); //-- Temporary solution

               // var result = Object.groupBy(data, ({ subject }) => subject);
               var result = data.reduce(function (r, a) {
                  r[a.subject] = r[a.subject] || [];
                  r[a.subject].push(a);
                  return r;
               }, Object.create(null));
               // result.sort()
               setDataSubject(result)
               setData(data);
               setDataContainer(response.data);
               setLoading(false);
               // setTimeout(() => {
               //    setLoading(false);
               //    console.log('stop loading')
               //  }, 2000);            

            }
         }, (error) => {
            console.log(error.message);
            setLoading(false)
            console.log('stop loading')
         });
      }
   }

   const getUpcomingList = () => {
      if (Number(user_id) > 0) {
         let root = `${process.env.REACT_APP_LMS_API}api/Lesson/listEntityLessonRecsbyTime`
         // let userstr = user.toString()         
         let url = root + `?userid=${user_id}&role=${role}&timeline=3`
         console.log(url)
         // setCurrentRole(role)
         console.log('loading')
         setLoading(true)
         setTabName("upcoming")
         axios.get(url).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               // var result = Object.groupBy(data, ({ subject }) => subject);
               var result = data.reduce(function (r, a) {
                  r[a.subject] = r[a.subject] || [];
                  r[a.subject].push(a);
                  return r;
               }, Object.create(null));
               // result.sort()
               setDataSubject(result)
               setData(data);
               setDataContainer(response.data);
               setLoading(false)
               // setTimeout(() => {
               //    setLoading(false)
               //    console.log('stop loading')
               //  }, 2000);            

            }
         }, (error) => {
            console.log(error.message);
            setLoading(false)
            console.log('stop loading')
         });
      }
   }

   const getPastList = () => {
      if (Number(user_id) > 0) {
         let root = `${process.env.REACT_APP_LMS_API}api/Lesson/listLessonRecs`
         let url = '';
         root = `${process.env.REACT_APP_LMS_API}api/Lesson/listEntityLessonRecsbyTime`
         url = root + `?userid=${user_id}&role=${role}&timeline=2`
         console.log(url)
         console.log('loading')
         setLoading(true)
         setTabName("past")

         axios.get(url).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               // var result = Object.groupBy(data, ({ subject }) => subject);
               var result = data.reduce(function (r, a) {
                  r[a.subject] = r[a.subject] || [];
                  r[a.subject].push(a);
                  return r;
               }, Object.create(null));
               // result.sort()
               setDataSubject(result)
               setData(data);
               setDataContainer(response.data);
               setLoading(false);
            }
         }, (error) => {
            console.log(error.message);
            setLoading(false)
            console.log('stop loading')
         });
      }
   }

   const handleTabChange = (key) => {
      setViewType(0)
      switch (key) {
         case "1":
            getMasterList();
            setActiveTab("1");
            break;
         case "2":
            getSharedList();
            setActiveTab("2");
            break;
         case "3":
            getCurrentList();
            setActiveTab("3");
            break;
         case "4":
            getUpcomingList();
            setActiveTab("4");
            break;
         case "5":
            getPastList();
            setActiveTab("5");
            break;
         // case "5":
         //    getRecycleBin();
         //    setActiveTab("5");
         //    break;
      }
   }

   const refreshList = (tab) => {
      switch (tab) {
         case "1":
            getMasterList();
            break;
         case "2":
            getSharedList();
            break;
         case "3":
            getCurrentList();
            break;
         case "4":
            getUpcomingList();
            break;
         case "5":
            getPastList();
            break;
         // case "5":
         //    getRecycleBin();
         //    break;
      }
   }

   const zoomPickerColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'zoom_account',
         key: 'zoom_account',
         hidden: true,
      },

      {
         title: 'Select Zoom Account',
         key: 'description',
         render: payload => (
            // <Tooltip placement="topLeft" title={title}>
            //    <span style={{cursor:"default"}}>{title}</span>
            // </Tooltip>
            <Popconfirm
               title="Are you sure you want to use this account?"
               onConfirm={() => handleZoomPickerRoutine(payload.zoom_account, 'start')}
               okText="Yes"
               cancelText="No"
            >
               <Button
                  className='button-shadow'
                  type='primary'
                  shape='round'
                  size='middle'
                  block
                  disabled={payload.status === 'in-use' ? true : false}
               // onClick={() => handleZoomPickerRoutine(payload.zoom_account, 'start')}
               >
                  {/* {payload.description} */}
                  <Text ellipsis style={{ color: "#fff" }}>{payload.description}</Text>
               </Button>
            </Popconfirm>

         ),
         width: '20%'
      },
      {
         title: 'Status',
         key: 'status',
         render: payload => (
            <Tag
               style={{ fontSize: 12, cursor: 'default' }}
               color={payload.status === 'available' ? 'green' : 'red'}
            >
               {payload.status.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
            </Tag>
         ),
         width: '7%'
      },
      {
         title: 'Current User',
         key: 'current_user_fullname',
         dataIndex: 'current_user_fullname',
         width: '15%'
      },
      {
         title: 'Lesson Title',
         key: 'lesson_title',
         ...getColumnSearchProps('lesson_title'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.lesson_title}>
               <Text>{object.lesson_title}</Text>
            </Tooltip>
         ),
         width: '30%'
      },
      {
         title: 'Lesson Subject',
         key: 'lesson_subject',
         ...getColumnSearchProps('lesson_subject'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.lesson_subject}>
               <Text>{object.lesson_subject}</Text>
            </Tooltip>
         ),
         width: '16%'
      },
      {
         title: 'Zoom Meeting ID',
         dataIndex: 'current_meeting_id',
         key: 'current_meeting_id',
         width: '12%'
      },
   ].filter(item => !item.hidden);

   const zoomObserveColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      // {
      //    title: '',
      //    dataIndex: 'zoom_account',
      //    key: 'zoom_account',
      //    hidden: true,
      // },
      {
         title: '',
         dataIndex: 'join_url',
         key: 'join_url',
         hidden: true,
      },
      {
         title: '',
         key: 'description',
         render: payload => (
            // <Tooltip placement="topLeft" title={title}>
            //    <span style={{cursor:"default"}}>{title}</span>
            // </Tooltip>
            <Popconfirm
               title="Are you sure you want to join this class?"
               onConfirm={() => handleObserveClass(payload.join_url)}
               okText="Yes"
               cancelText="No"
            >
               <Button
                  className='button-shadow'
                  type='primary'
                  shape='round'
                  size='middle'
                  block
               // disabled={payload.status === 'in-use' ? true : false} 
               // onClick={() => handleZoomPickerRoutine(payload.zoom_account, 'start')}
               >
                  Observe/Join
               </Button>
            </Popconfirm>

         ),
         width: '15%'
      },

      {
         title: 'Teacher Name',
         key: 'current_user_fullname',
         dataIndex: 'current_user_fullname',
         width: '15%'
      },
      {
         title: 'Lesson Title',
         key: 'lesson_title',
         ...getColumnSearchProps('lesson_title'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.lesson_title}>
               <Text>{object.lesson_title}</Text>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'Lesson Subject',
         key: 'lesson_subject',
         ...getColumnSearchProps('lesson_subject'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.lesson_subject}>
               <Text>{object.lesson_subject}</Text>
            </Tooltip>
         ),
         width: '18%'
      },
      {
         title: 'Level',
         key: 'grade_level',
         ...getColumnSearchProps('grade_level'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.grade_level}>
               <Text>{object.grade_level}</Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Section',
         key: 'section',
         ...getColumnSearchProps('section'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.section}>
               <Text>{object.section}</Text>
            </Tooltip>
         ),
         width: '15%'
      },
      // {
      //    title: 'Zoom Meeting ID',
      //    dataIndex: 'current_meeting_id',
      //    key: 'current_meeting_id',
      //    width: '14%'
      // },
   ].filter(item => !item.hidden);

   const lessonAttendanceColumns = [
      {
         title: '',
         dataIndex: 'lessonId',
         key: 'lessonId',
         hidden: true,
      },
      {
         title: 'Student Name',
         key: 'student_name',
         ...getColumnSearchProps('student_name'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.student_name}>
               <Text style={{ textTransform: "capitalize" }}>{object.student_name.toLowerCase()}</Text>
            </Tooltip>
         ),
         width: '65%'
      },
      {
         title: 'Time Viewed',
         key: 'access_time',
         dataIndex: 'access_time',
         width: '35%'
      },
   ].filter(item => !item.hidden);

   const handleDeleteLesson = (key, payload) => {
      // console.log(e);
      // message.success('Click on Yes (' + key + ')');
      let staffid = payload.staffid;

      if (user_id != staffid) {
         Modal.error({
            title: 'Lesson',
            content: 'Deleting Lesson not allowed',
            centered: true
         });
      }
      else {
         axios.post(`${process.env.REACT_APP_API_LESSON}/deleteLesson?lessonid=${key}`).then((response) => {
            if (response.data) {
               const data = response.data;
               Modal.success({
                  title: 'Lesson',
                  content: 'Your lesson has been deleted.',
                  centered: true,
                  onOk: refreshList(activeTab)
               });
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Lesson',
               content: 'Error deleting lesson.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         });
      }
   }

   // const handleDups = (payload) => {
   //    console.log(payload.key);
   //    Modal.success({
   //       content: `key value ${payload.studentviewing}`,
   //       centered: true,
   //       onOk: refreshList(activeTab)
   //    });
   // }

   const handleDuplicateLesson = (key, payload) => {
      // console.log(e);
      // message.success('Click on Yes (' + key + ')');
      let staffid = payload.staffid
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      var newlessonid = "lesson_" + o2o_mode + "_" + uuid();
      // if (user_id != staffid) {
      //    Modal.error({
      //       content: 'Duplicating Lesson not allowed',
      //       centered: true
      //    });
      // } 
      // else {
      // axios.post(`${process.env.REACT_APP_API_LESSON}/duplicateLesson?lessonid=${key}&newlessonid=${newlessonid}`).then((response) => {
      axios.post(`${process.env.REACT_APP_API_LESSON}/duplicateLesson?lessonid=${key}&newlessonid=${newlessonid}&staffid=${user_id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Lesson',
               content: 'Your lesson has been duplicated.',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Lesson',
            content: 'Error duplicating lesson.',
            centered: true,
            onOk: refreshList(activeTab)
         });
      });
      // }     
   }

   const handleCreateLesson = () => {
      var params = { key: ``, value: 'myvalue' };
      dispatch(reset_lesson(params));

      // {createView && <Navigate to={'/lesson/createlesson'} replace state={{}}/>}
      navigate('/lesson/createlesson', { state: { userId: user_id } });
   };

   const handleAssignAssessment = (ass_id, title, term, level, students) => {

      axios.get(`${process.env.REACT_APP_API_LESSON}/assigninfo?lessonid=${ass_id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            let ass_options = {
               id: data.id,
               assessment_id: data.assessment_id,
               assigned_by: data.assigned_by,
               duration: data.duration.toString(),
               allowed_attempts: data.allowed_attempts.toString(),
               passing_percentage: data.passing_percentage.toString(),
               allow_result_viewing: data.allow_result_viewing.toString(),
               enable_timer: data.enable_timer.toString(),
               start_date: data.start_date,
               end_date: data.end_date,
               assigned_students: data.assigned_students,
               term: data.term,
               level: data.level
            };

            setAssOptions(ass_options);
         }
      }, (error) => {
         console.log(error.message);
      });

      setAssTitle(title);
      setAssignVisible(true);

      let ass_options_default = {
         id: '',
         assessment_id: '',
         assigned_by: '1',
         duration: '1',
         allowed_attempts: '1',
         passing_percentage: '50',
         allow_result_viewing: '0',
         enable_timer: '0',
         start_date: '',
         end_date: '',
         assigned_students: students,
         term: term,
         level: parseInt(level)
      }
      setKey(ass_id);
      setAssOptions(ass_options_default);
      setAssTitle(title);
      setAssignVisible(true);
   };

   const handleEditLesson = (key, payload) => {
      let staffid = payload.staffid;
      /*      console.log(`staffid ${staffid}`);
           console.log(`user ${g_user_id}`); */

      if (parseInt(user_id) !== staffid) {
         Modal.error({
            title: 'Lesson',
            content: 'Editting Lesson not allowed',
            centered: true
         });
      } else {
         setKey(key);
         setEditView(false);
         setAssignVisible(false);
         // var params = {key: ``, value : 'myvalue'}
         // dispatch(initialize_lesson(params))
         SearchLessonId(key);
         PostLookup();
      }
   };

   const SearchLessonId = async (key) => {
      console.log('SearchLessonId')
      let url = `${process.env.REACT_APP_API_LESSON}/getLessonItem?lessonid=${key}`
      console.log(url)

      await axios.get(url).then((response) => {
         console.log(`SearchLessonId fetch result ${key}`);
         console.log(response);

         if (response.data) {
            const data = response.data;
            console.log(data);
            let tmpsection = []
            var i;

            for (i = 0; i < data.sectionList.length; i++) {
               // loopData += `<li>${data[i].name}</li>`
               tmpsection.push(data.sectionList[i])
            }

            let date1 = data.availabilityDate[0]
            let date2 = data.availabilityDate[1]
            let itemlesson = {
               lessonid: data.lessonId,
               title: data.title,
               techearName: "",
               subject: data.subject,
               overview: data.overview,
               term: parseInt(data.term),
               startdate: date1,
               enddate: date2,
               availabilitydate: data.availabilityDate,
               display_availabilitydate: date1 + '-' + date2,
               fromdate: data.availabilityFromDate,
               todate: data.availabilityToDate,
               educationallevel: data.educationalLevel,
               studentviewing: data.studentViewing,
               lessonsharing: data.lessonSharing,
               gradelevel: data.gradeLevel,
               sectionlist: tmpsection,
               assigned_students: data.assignedStudents,
               selected_students: data.selectedStudents,
               filteredsection: [],
               learningtype: data.learningType,
               learningmode: data.learningMode,
               conference: data.conference,
               conferencelink: data.conferenceLink,
               classroomid: data.classRoomId,
               learningplan: "",
               type: "",
               sections: data.sections,
               staffId: data.staffId,
               join_link: data.joinLink,
               assigned_students_with_section: data.assigned_students_with_section
               // sections: [
               //    {
               //       id : '',
               //       title : '',
               //       contents: []
               //       }  
               // ]
            }

            dispatch(set_lessonrec(itemlesson));
            // setEditLessonView(true); // set this flag to true once data is fully loaded

            // <Navigate to={`/lesson/editlesson?Id=${key}`} replace state={{}}/>
            navigate(`/lesson/editlesson?Id=${key}`);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const PostLookup = () => {
      let urllink = `${process.env.REACT_APP_API_LESSON_LOOKUP}`
      // console.log(urllink)
      axios.get(urllink).then((response) => {
         console.log(response)
         dispatch(post_lookup(response.data))
      })
   }

   const handlePublish = (key, payload) => {
      let studentview = payload.studentviewing
      let staffid = payload.staffid
      if (user_id != staffid) {
         Modal.error({
            title: 'Lesson',
            content: 'Changing Status not allowed',
            centered: true
         });
      } else {
         setPublishModal(true)
         setStudentViewing(studentview);
         setLessonId(key);
      }
   }

   const handleonOkPublish = () => {
      let field = 'studentviewing';

      let key = lessonId;
      let publish = studentviewing;

      setPublishModal(false)
      axios.post(`${process.env.REACT_APP_API_LESSON}/updateLessonProperty?lessonid=${key}&field=${field}&fieldvalue=${publish}`).then((response) => {
         if (response.data) {
            // const data = response.data;
            Modal.success({
               title: 'Lesson',
               content: 'Lesson Publish Status Changed Successfully',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Lesson',
            content: 'Error updating lesson.',
            centered: true,
            onOk: refreshList(activeTab)
         });
      });

   }

   const handleShare = (key, payload) => {
      let lesson_sharing = payload.lessonsharing
      let staffid = payload.staffid
      if (user_id !== staffid.toString()) {
         Modal.error({
            title: 'Lesson',
            content: 'Changing Status not allowed',
            centered: true
         });
      } else {
         setLessonSharing(lesson_sharing)
         setLessonId(key);
         setShareModal(true)
      }

   }

   const handleonOkShare = () => {
      let field = 'lessonsharing'
      let sharing = lessonsharing;
      let key = lessonId;
      setShareModal(false)
      axios.post(`${process.env.REACT_APP_API_LESSON}/updateLessonProperty?lessonid=${key}&field=${field}&fieldvalue=${sharing}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Lesson',
               content: 'Lesson Sharing Status Changed Successfully',
               centered: true,
               onOk: refreshList(activeTab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Lesson',
            content: 'Error updating lesson.',
            centered: true,
            onOk: refreshList(activeTab)
         });
      });

   }

   const handleChangeStudentViewing = (e) => {
      console.log(e.target.value)
      setStudentViewing(e.target.value)
   }

   const handleChangeLessonSharing = (e) => {
      console.log(e.target.value)
      setLessonSharing(e.target.value)
   }

   const PostLesson = (lessonInfo, roomid, conference_link, join_link, zoom_meeting_id) => {
      console.log('PostLesson');
      let date1 = lessonInfo.availabilityDate[0]
      let date2 = lessonInfo.availabilityDate[1]
      let dvalue = date1 + "-" + date2;

      let lesson = {
         LessonId: lessonInfo.lessonId,
         Title: lessonInfo.title,
         TeacherName: lessonInfo.teacherName,
         Subject: lessonInfo.subject,
         Overview: lessonInfo.overview,
         Term: lessonInfo.term,
         AvailabilityDate: dvalue,
         EducationalLevel: lessonInfo.educationalLevel,
         StudentViewing: lessonInfo.studentViewing,
         // StudentViewing: "allowed",  //-- Force the student viewing to allowed when the teacher starts the lesson
         LessonSharing: lessonInfo.lessonSharing,
         GradeLevel: lessonInfo.gradeLevel,
         SectionList: lessonInfo.sectionList,
         AssignedStudents: lessonInfo.assignedStudents,
         LearningType: lessonInfo.learningType,
         LearningMode: lessonInfo.learningMode,
         Conference: lessonInfo.conference,
         ConferenceLink: conference_link,
         ClassRoomId: roomid,
         LearningPlan: lessonInfo.learningPlan,
         Type: "",
         Sections: lessonInfo.sections,
         SectionVersion: 2,
         StaffId: lessonInfo.staffId,
         JoinLink: join_link,
         ZoomMeetingID: zoom_meeting_id,
      }

      let url = `${process.env.REACT_APP_LMS_API}api/Lesson/addLesson`

      axios.post(url, lesson).then(response => {
         if (response.data) {
            console.log('Lesson updated');

            if (join_link) {
               window.open(join_link, "_blank");
            }
            // else {
            //    //-- Remove teacher from breakout rooms
            //    let url = `${process.env.REACT_APP_API_ZOOM}/zoomgetmeeting?id=${g_zoom_meeting_id}`;
            //    axios.get(url, { headers: {} }).then((response) => {
            //       if (response.data) {
            //          let zoomMeeting = response.data;

            //          //-- Add teacher to breakout room (section name) where current lesson is assigned
            //          var teacherEmail = g_email;

            //          var rooms = zoomMeeting.settings.breakout_room.rooms;

            //          for (var i = 0; i < rooms.length; i++) {
            //             var participants = rooms[i].participants;
            //             var itemIdx = participants.findIndex(item => item === teacherEmail);

            //             //-- Remove teacher from other breakout rooms if included
            //             if (itemIdx >= 0)
            //                participants.splice(itemIdx, 1);

            //             //--Update room participants
            //             rooms[i].participants = participants;
            //          }

            //          //-- (Start) Update breakout rooms data
            //          const options = {
            //             "settings": {
            //                "breakout_room": {
            //                   "rooms": rooms,
            //                }
            //             }
            //          }

            //          url = `${process.env.REACT_APP_API_ZOOM}/zoomupdatemeeting?id=${g_zoom_meeting_id}`;
            //          axios.patch(url, options, { headers: {} }).then((response) => {
            //             if (response.data) {

            //                // PostLesson(lesson_data, "", zoomMeeting.start_url, zoomMeeting.join_url, zoomMeeting.id);
            //             }
            //          }, (error) => {
            //             Modal.error({
            //                title: 'Lesson',
            //                content: 'Looks like there was an error joining zoom meeting',
            //                centered: true,
            //             });
            //          });
            //          //-- (End) Update breakout rooms data
            //       }
            //    }, (error) => {
            //       Modal.error({
            //          title: 'Lesson',
            //          content: 'Looks like there was an error joining zoom meeting',
            //          centered: true,
            //       });
            //    });
            // }
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleJoinLesson = (key, level) => {
      let studentviewing = '';
      setKey(key);
      console.log(`key: ` + key);

      if (role === 'Student') {
         axios.get(`${process.env.REACT_APP_API_LESSON}/getLessonItem?lessonid=${key}`).then((response) => {
            console.log(`fetch result ${key}`);
            console.log(response);

            if (response.data) {
               const data = response.data;
               studentviewing = data.studentViewing;

               if (data.learningMode === 'online' || data.learningMode === 'hyflex') {
                  //if (data.joinLink !== '' && data.joinLink !== null) {
                  // logAttendance(key, 'in');
                  // openConference(key);
                  openConference(data);
                  // setViewLesson(true);

                  if (studentviewing === 'allowed') {
                     navigate(`/subject/view?Id=${key}&level=${level}&version=2`);
                  }
                  // navigate(`/subject/view?Id=${key}`);
                  //}
                  /*
                  else {
                     Modal.info({
                        title: 'Lesson',
                        content: 'Joining class is not yet allowed',
                        centered: true
                     });
                     setViewLesson(false);
                  }
                  */
               }
               else {
                  if (studentviewing === 'allowed') {
                     logAttendance(key, 'in');
                     // navigate(`/subject/view?Id=${key}`);
                     navigate(`/subject/view?Id=${key}&level=${level}&version=2`);
                  } else {
                     Modal.info({
                        title: 'Lesson',
                        content: 'Joining class is currently not allowed',
                        centered: true
                     });
                     setViewLesson(false);
                  }
               }
            }
         }, (error) => {
            console.log(error.message);
         });
      }
      else {
         // setViewLesson(true);
         navigate(`/subject/view?Id=${key}&level=${level}&version=2`);
         // navigate(`/subject/view?Id=${key}`);
      }
   }

   const handleStartLesson2 = (lesson_id, stat, type, level, section) => {
      console.log(`key: ` + lesson_id);
      setKey(lesson_id);
      // let stat=initiatorStatus;
      // setInitiator(lesson_id,stat);

      axios.get(`${process.env.REACT_APP_API_LESSON}/getLessonItem?lessonid=${lesson_id}`).then((response) => {
         if (response.data) {
            let lesson_data = response.data;
            setLessonData(lesson_data);

            if (type === "online" || type === "hyflex") {
               openConference(lesson_data, level, section);
            }
            else
               handleViewLesson(lesson_id, type, level);
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Lesson',
            content: 'Looks like problems occured when entering online class.',
            centered: true,
            onOk: refreshList(activeTab)
         });
      });
   }

   const openConference = (lesson_data, level = null, section) => {
      if (lesson_data.learningMode === 'online' || lesson_data.learningMode === 'hyflex') {

         if (role === 'Admin' || role === 'Teacher' || role === 'Co-Teacher') {

            if (lesson_data.conference === "brainee") {
               if (neukol_user_id !== "" && neukol_user_id !== null) {
                  handleViewLesson(lesson_data.lessonId, lesson_data.learningMode);
                  handleTeacherBraineeRoutine(lesson_data);
               }
               else {

                  PostLesson(lesson_data, "", "", "", "");

                  Modal.error({
                     title: 'Lesson',
                     content: 'Neukol account not available.',
                     centered: true
                  });
               }
            }
            else if (lesson_data.conference === "googlemeet") {

               if (google_meet_link !== "" && google_meet_link !== null) {
                  handleViewLesson(lesson_data.lessonId, lesson_data.learningMode, level);

                  PostLesson(lesson_data, "", google_meet_link, google_meet_link, "");
               } else {
                  Modal.error({
                     title: 'Lesson',
                     content: 'Google meet link not available.',
                     centered: true
                  });
               }
            }
            else if (lesson_data.conference === "zoom") {
               if (g_zoom_account !== '' && g_zoom_account !== null) {
                  // handleViewLesson(lesson_data.lessonId, lesson_data.learningMode, level);
                  // handleTeacherZoomRoutine(lesson_data, section);

                  let url = `${process.env.REACT_APP_API_CPH}/joinzoom?e=${g_zoom_account}`;
                  axios.get(url).then((response) => {
                     var join_url = response.data;
                     PostLesson(lesson_data, "", "", join_url, "");
                     handleViewLesson(lesson_data.lessonId, lesson_data.learningMode, level);
                  }, (error) => {
                     console.log(error.message);
                  });
               }
               else {
                  PostLesson(lesson_data, "", "", "", "");

                  Modal.error({
                     title: 'Lesson',
                     content: 'Zoom account not available.',
                     centered: true
                  });
               }
            }
         }
         else {
            //-- Role is Student
            if (lesson_data.conference === "brainee") {
               if (lesson_data.classRoomId !== "") {
                  handleStudentBraineeRoutine(lesson_data);
               }
               else {
                  Modal.info({
                     title: 'Lesson',
                     content: 'Unable to join the class',
                     centered: true
                  });
               }
            }
            else if (lesson_data.conference === "googlemeet") {
               let conferencelink = lesson_data.conferenceLink;

               if (conferencelink !== "" && conferencelink !== null)
                  window.open(conferencelink, "_blank").focus();
               else {
                  Modal.info({
                     title: 'Lesson',
                     content: 'Unable to join the class',
                     centered: true
                  });
               }
            }
            else if (lesson_data.conference === "zoom") {
               let url = `${process.env.REACT_APP_API_CPH}/joinzoom?e=${g_zoom_account}`;
               axios.get(url).then((response) => {
                  var join_url = response.data;

                  if (join_url !== "" && join_url !== null)
                     window.open(join_url, "_blank");
                  else {
                     Modal.info({
                        title: 'Lesson',
                        content: 'Unable to join the class',
                        centered: true
                     });
                  }

                  //handleViewLesson(lesson_data.lessonId, lesson_data.learningMode);
               }, (error) => {
                  console.log(error.message);
               });
            }
         }
      }
   }

   const showZoomPicker = () => {
      setLoading(true);

      let url = `${process.env.REACT_APP_API_ZOOM}/zoompickerlist`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            // setZoomPickerList(data);
            // setZoomPickerModal(true);
            setLoading(false);
            setZoomPickerOptions({
               Modal: true,
               List: data
            });
         }
      }, (error) => {
         setLoading(false);
         console.log(error.message);
      });
   }

   const handleZoomPickerRoutine = (zoom_account, mode) => {
      if (mode === 'start') {
         let startTime = new Date().getTime();

         const options = {
            "topic": lessonData.title,
            "type": 2,
            "start_time": startTime,
            "duration": 60,
            "timezone": "Asia/Manila",
            "password": "braineelms",
            "agenda": "",
            "schedule_for": zoom_account,
            "settings": {
               "join_before_host": false,
               "host_video": true,
               "participant_video": true,
               "mute_upon_entry": false,
               "enforce_login": false,
               "auto_recording": "none",
               "alternative_hosts": "",
            }
         }

         // var token = ZoomService.GenerateJWTKey();
         let token = "not needed anymore";

         // let url = `${process.env.REACT_APP_API_ZOOM}/zoomcreatemeeting?zoom_account=${zoom_account}&token=${token}`;
         let url = `${process.env.REACT_APP_API_ZOOM}/zoomcreatemeeting?zoom_account=${zoom_account}`;
         axios.post(url, options, { headers: {} }).then((response) => {
            if (response.data) {

               let data0 = response.data;

               let zoom_picker_options = {
                  Zoom_account: zoom_account,
                  Current_user_id: user_id,
                  Lesson_id: lessonData.lessonId,
                  Current_meeting_id: data0.id,
                  Zoom_token: token,
                  Start_url: data0.start_url,
                  Join_url: data0.join_url,
               }

               //-- Update the status of selected zoom account
               url = `${process.env.REACT_APP_API_ZOOM}/zoompickerupdate?mode=start`;
               axios.post(url, zoom_picker_options, { headers: {} }).then((response) => {
                  if (response.data) {
                     let data1 = response.data;

                     if (data1 === 1) {
                        axios.post(`${process.env.REACT_APP_LMS_API}api/LessonInitiator/addInitiator?lessonid=${lessonData.lessonId}&staffid=${user_id}&stat=${mode}`).then((response) => {
                           if (response.data) {
                              const data = response.data;
                              //--Close zoom picker modal
                              setZoomPickerOptions({
                                 Modal: false,
                                 List: []
                              });

                              handleViewLesson(lessonData.lessonId, lessonData.learningMode);
                              PostLesson(lessonData, "", data0.start_url, data0.join_url, data0.id);
                           }
                        }, (error) => {
                           console.log(error.message);
                           Modal.error({
                              title: 'Lesson',
                              content: 'Error updating Lesson Status',
                              centered: true,
                              onOk: refreshList(activeTab)
                           });
                        });
                     }
                     else {
                        Modal.info({
                           title: 'Lesson',
                           content: 'Someone else is already using the account you selected.',
                           centered: true,
                        });
                     }
                  }
               }, (error) => {
                  console.log(error.message);
                  Modal.error({
                     title: 'Lesson',
                     content: 'It looks like there was an error in creating zoom meeting',
                     centered: true,
                  });
               });
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Lesson',
               content: 'It looks like there was an error in creating zoom meeting',
               centered: true,
            });
         });
      }
      else {
         var token = ZoomService.GenerateJWTKey();

         let zoom_picker_options = {
            Zoom_account: zoom_account,
            Current_user_id: user_id,
            Lesson_id: lessonData.lessonId,
            Current_meeting_id: '',
            Zoom_token: token,
            Start_url: '',
            Join_url: '',
         }

         let url = `${process.env.REACT_APP_API_ZOOM}/zoompickerupdate?mode=end`;
         axios.post(url, zoom_picker_options, { headers: {} }).then((response) => {
            if (response.data) {
               let data1 = response.data;
            }
         }, (error) => {
            console.log(error.message);
         });
      }
   }

   const handleTeacherZoomRoutine = (lesson_data, section) => {
      let url = `${process.env.REACT_APP_API_ZOOM}/zoomgetmeeting?id=${g_zoom_account}`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let zoomMeeting = response.data;

            //-- Add teacher to breakout room (section name) where current lesson is assigned
            var teacherEmail = g_email;
            var classSection = section;

            var rooms = zoomMeeting.settings.breakout_room.rooms;

            for (var i = 0; i < rooms.length; i++) {
               var participants = rooms[i].participants;
               var itemIdx = participants.findIndex(item => item === teacherEmail);

               //-- Remove teacher from other breakout rooms if included
               if (itemIdx >= 0)
                  participants.splice(itemIdx, 1);

               //-- Add teacher to breakout room where current lesson is assigned
               if (rooms[i].name === classSection) {
                  rooms[i].participants.push(teacherEmail);
               }

               //--Update room participants
               rooms[i].participants = participants;
            }

            //-- (Start) Update breakout rooms data
            const options = {
               "settings": {
                  "breakout_room": {
                     "rooms": rooms,
                  }
               }
            }

            // url = `${process.env.REACT_APP_API_ZOOM}/zoomupdatemeeting?id=${g_zoom_meeting_id}`;
            // axios.patch(url, options, { headers: {} }).then((response) => {
            //    if (response.data) {

            //       PostLesson(lesson_data, "", zoomMeeting.start_url, zoomMeeting.join_url, zoomMeeting.id);
            //    }
            // }, (error) => {
            //    Modal.error({
            //       title: 'Lesson',
            //       content: 'Looks like there was an error joining zoom meeting',
            //       centered: true,
            //    });
            // });

            PostLesson(lesson_data, "", zoomMeeting.start_url, zoomMeeting.join_url, zoomMeeting.id);

            //-- (End) Update breakout rooms data
         }
      }, (error) => {
         Modal.error({
            title: 'Lesson',
            content: 'Looks like there was an error joining zoom meeting',
            centered: true,
         });
      });
   }

   const handleTeacherBraineeRoutine = (lesson_data) => {
      let apikey = `${process.env.REACT_APP_NEUKOL_API_KEY}`; //"Fg5vwWhXIx0N4T8X"

      let expire = new Date().getTime() + 1000 * 60 * 60 * 2;
      let timestampz = new Date().getTime();
      let startTime = new Date().getTime();
      console.log('Create Classroom Id');

      let body = {
         timestamp: timestampz,
         sid: `${process.env.REACT_APP_NEUKOL_SID}`,
         sign: "",
         title: lesson_data.title,
         expire: expire,
         teacher: neukol_user_id,
         students: "student",
         startTime: startTime,
         // endTime: startTime + 60 * 60 * 1000,
         resolutionType: "RESOLUTION_480P",
         music: 0,
         layout: 2,
         readyTime: 120000,
         extraTime: 120000,
         platformCount: 7,
         checkMember: false,
         picMonitor: false,
         localRecord: "ALLOW_RECORD"
      }

      // console.log('Body data for class room id');
      // console.log(body);

      var databody = "";
      let param = "";
      const keys = Object.keys(body);
      // console.log(keys)
      keys.sort();
      keys.forEach((key, index) => {
         console.log(`${key}: ${body[key]}`);
         if (key === 'sign') {
            console.log(`key => ${key}`);

         } else {
            databody += `${key}=${body[key]}`
         }
      });

      let keyitem = param + databody + apikey;
      let encrypted = CryptoJS.MD5(keyitem).toString(CryptoJS.enc.Hex);
      console.log(`encrypted ${encrypted}`);
      let postbody = { ...body, sign: encrypted };
      startTime = new Date().getTime();
      postbody = { ...body, sign: encrypted };
      let newbody = qs.stringify(postbody);

      console.log(postbody);

      const headers = {
         'Access-Control-Allow-Origin': '*',
         'Content-Type': 'application/x-www-form-urlencoded'
      };

      let url = `${process.env.REACT_APP_NEUKOL_API_CREATE}`;
      axios.post(url, newbody, { headers: headers }).then(response => {
         if (response.data) {
            console.log('Create Jump Link');

            // console.log(response.data)
            let resp = response.data;
            let classRmId = resp.response.classroomId;
            let timestampz = new Date().getTime();

            let teachertName = "";
            if (user_full_name.length > 20) {
               teachertName = user_full_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
               teachertName = teachertName.substring(0, 17) + "...";
            } else {
               teachertName = user_full_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            }

            let body = {
               timestamp: timestampz,
               sid: `${process.env.REACT_APP_NEUKOL_SID}`,
               sign: "",
               userId: neukol_user_id,
               name: teachertName,
               classroomId: classRmId
            }

            // console.log('Body data for jump link');
            // console.log(body);

            var databody = "";
            let param = "";
            const keys = Object.keys(body);
            // console.log(keys)
            keys.sort();
            keys.forEach((key, index) => {
               console.log(`${key}: ${body[key]}`);
               if (key === 'sign') {
                  console.log(`key => ${key}`);

               } else {
                  databody += `${key}=${body[key]}`;
               }
            });

            let keyitem = param + databody + apikey;
            let encrypted = CryptoJS.MD5(keyitem).toString(CryptoJS.enc.Hex);
            console.log(`encrypted ${encrypted}`);
            let postbody = { ...body, sign: encrypted };
            postbody = { ...body, sign: encrypted };
            let newbody = qs.stringify(postbody);
            console.log(postbody);

            const headers = {
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/x-www-form-urlencoded'
            };

            let url = `${process.env.REACT_APP_NEUKOL_API_GETLINK}`;
            axios.post(url, newbody, { headers: headers }).then(response => {
               if (response.data) {
                  let resp = response.data;

                  let resp_hdr = resp.responseHeader;

                  if (resp_hdr.msg === "Success") {
                     let jumplink = resp.response;
                     console.log("Jumplink: " + jumplink.loginLinkUrl);
                     let jlink = jumplink.loginLinkUrl;

                     PostLesson(lesson_data, classRmId, jlink, "", "");
                  }
               }
            }, (error) => {
               console.log(error.message);
               Modal.error({
                  title: 'Lesson',
                  content: 'Getting Jumplink Failed.',
                  centered: true
               });
            });
         }
      }, (error) => {
         console.log(error.message);
         // setClassroomid("");
         Modal.error({
            title: 'Lesson',
            content: 'Getting Class Room ID Failed.',
            centered: true
         });
      });
   }

   const handleStudentBraineeRoutine = (lesson_data) => {
      let apikey = `${process.env.REACT_APP_NEUKOL_API_KEY}`; //"Fg5vwWhXIx0N4T8X"
      console.log('Create Jump Link');
      let timestampz = new Date().getTime();
      let studentName = "";
      if (user_full_name.length > 20) {
         studentName = user_full_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
         studentName = studentName.substring(0, 17) + "...";
      } else {
         studentName = user_full_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      }

      let body = {
         timestamp: timestampz,
         sid: `${process.env.REACT_APP_NEUKOL_SID}`,
         sign: "",
         userId: user_id,
         name: studentName,
         classroomId: lesson_data.classRoomId
      }

      // console.log('Body data for jump link');
      // console.log(body);

      var databody = ""
      let param = ""
      const keys = Object.keys(body);
      // console.log(keys)
      keys.sort();
      keys.forEach((key, index) => {
         console.log(`${key}: ${body[key]}`);
         if (key === 'sign') {
            console.log(`key => ${key}`)

         } else {
            databody += `${key}=${body[key]}`
         }
      });

      let keyitem = param + databody + apikey;
      let encrypted = CryptoJS.MD5(keyitem).toString(CryptoJS.enc.Hex);
      console.log(`encrypted ${encrypted}`);
      let postbody = { ...body, sign: encrypted };
      postbody = { ...body, sign: encrypted };
      let newbody = qs.stringify(postbody);
      console.log(postbody);

      const headers = {
         'Access-Control-Allow-Origin': '*',
         'Content-Type': 'application/x-www-form-urlencoded'
      };

      let url = `${process.env.REACT_APP_NEUKOL_API_GETLINK}`;
      axios.post(url, newbody, { headers: headers }).then(response => {
         if (response.data) {
            let resp = response.data;

            let resp_hdr = resp.responseHeader

            if (resp_hdr.msg === "Success") {
               let jumplink = resp.response
               console.log("Jumplink: " + jumplink);
               console.log(jumplink.loginLinkUrl)
               let jlink = jumplink.loginLinkUrl;

               window.open(jlink, "_blank").focus();
            }
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            content: 'Getting Jumplink Failed.',
            centered: true
         });
      });
   }

   const handleViewLesson = (key, learning_mode, level = null) => {
      let studentviewing = '';
      setKey(key);
      setLevelId(level);
      console.log(`key: ` + key);

      if (role === 'Student') {
         axios.get(`${process.env.REACT_APP_API_LESSON}/getLessonItem?lessonid=${key}`).then((response) => {
            console.log(`fetch result ${key}`);
            console.log(response);

            if (response.data) {
               const data = response.data;
               studentviewing = data.studentViewing;
               console.log(studentviewing);

               if (studentviewing === 'allowed') {
                  // setViewLesson(true);
                  logAttendance(key, 'in');
                  navigate(`/subject/view?Id=${key}&level=${level}&version=2`);
                  // navigate(`/subject/view?Id=${key}`);

               } else {
                  Modal.error({
                     title: 'Lesson',
                     content: 'Lesson Viewing is not allowed',
                     centered: true
                  });

                  setViewLesson(false);
               }
            }
         }, (error) => {
            console.log(error.message);
         });
      } else {
         // setViewLesson(true);
         // {viewLesson && <Navigate to={`/subject/view?Id=${key}&level=${levelId}`} replace state={{}}/>}

         if (learning_mode === "online" || learning_mode === "hyflex") {
            axios.post(`${process.env.REACT_APP_LMS_API}api/LessonInitiator/addInitiator?lessonid=${key}&staffid=${user_id}&stat=start`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  navigate(`/subject/view?Id=${key}&level=${level}&version=2`);
                  // navigate(`/subject/view?Id=${key}`);
               }
            }, (error) => {
               console.log(error.message);
               Modal.error({
                  title: 'Lesson',
                  content: 'Error updating Lesson Status',
                  centered: true,
                  onOk: refreshList(activeTab)
               });
            });
         }
         else {
            navigate(`/subject/view?Id=${key}&level=${level}&version=2`);
         }
      }
   }

   const logAttendance = (lessonid, stat) => {
      axios.post(`${process.env.REACT_APP_LMS_API}api/LessonAttendance/postAttendance?lessonid=${lessonid}&studentid=${user_id}&stat=${stat}`).then((response) => {
         if (response.data) {
            const data = response.data;
            // Modal.success({
            //    content: 'Lesson Sharing Status Changed Successfully',
            //    centered: true,
            //    onOk: refreshList(activeTab)
            // });
         }
      }, (error) => {
         console.log(error.message);
         // Modal.error({
         //    content: 'Error logging attendance',
         //    centered: true,
         //    onOk: refreshList(activeTab)
         // });        
      });
   }

   const handleEditTopic = (key, level) => {
      // setKey(key);
      // setEditView(true);
      // setLevelId(level);

      axios.get(`${process.env.REACT_APP_API_LESSON}/getLessonItem?lessonid=${key}`).then((response) => {
         console.log(`fetch result ${key}`);
         console.log(response);

         if (response.data) {
            const data = response.data;
            let tmpsection = []
            var i;
            for (i = 0; i < data.sectionList.length; i++) {
               // loopData += `<li>${data[i].name}</li>` 
               tmpsection.push(data.sectionList[i])
            }
            let itemlesson = {
               lessonid: data.lessonId,
               title: data.title,
               techearName: data.teacherName,
               subject: data.subject,
               overview: data.overview,
               term: data.term,
               availabilitydate: data.availabilityDate,
               display_availabilitydate: "",
               educationallevel: data.educationalLevel,
               studentviewing: data.studentViewing,
               assignedstudents: data.assignedStudents,
               lessonsharing: data.lessonSharing,
               gradelevel: data.gradeLevel,
               sectionlist: tmpsection,
               filteredsection: [],
               learningtype: data.learningType,
               learningmode: data.learningMode,
               learningplan: "",
               type: "",
               sections: [
               ]
            }

            dispatch(set_lesson(itemlesson));

            // {editView && <Navigate to={`/subject/edit?Id=${key}&level=${levelId}`} replace state={{}}/>}
            navigate(`/subject/edit?Id=${key}&level=${level}`);
         }
      }, (error) => {
         console.log(error.message);
      });
   };

   const handleSaveAssignment = (data) => {
      // message.info(JSON.stringify(data));
      if (data.id === '') { //--New assignment
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var assignmentData = data;

         assignmentData.id = "ass_assign_" + o2o_mode + "_" + uuid();

         // message.info(JSON.stringify(assignmentData));

         let url = `${process.env.REACT_APP_API_SUMMATIVE_ASSESSMENT}/assignwlesson?lessonid=${key}`
         axios.post(url, assignmentData).then(response => {
            if (response.data) {
               const data = response.data;
               notification.success({
                  message: `Assessment Assignment`,
                  description: 'Assessment was assigned successfully',
               });
            }
         }, (error) => {
            console.log(error.message);
            notification.error({
               message: `Assessment Assignment`,
               description: 'Assessment assignment failed',
            });
         });


         //
      } else { //-- Edit assignment
         const url = `${process.env.REACT_APP_API_SUMMATIVE_ASSESSMENT}/assignupdate`
         axios.post(url, data).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               // message.success('Assessment was created.');
               // Modal.success({
               //    content: 'Assessment was assigned.',
               //    centered: true,
               // });
               notification.success({
                  message: `Assessment Assignment`,
                  description: 'Assessment assignment was updated successfully',
               });
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            notification.error({
               message: `Assessment Assignment`,
               description: 'Assessment assignment update failed',
            });
         });
      }
   }

   const handleSetOptions = (data) => {
      // message.info(JSON.stringify(data));
      let tempAssOptions = null;
      setAssessmentid(data.assessment_id)
      console.log(`assessmentid ${assessmentid}`)
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);

      // onDrawerClose()
   }

   const onDrawerClose = () => {
      setAssignVisible(false);
   }

   // const renderDrawer = () => {
   //    return <>
   //       <Drawer
   //          title={assTitle}
   //          placement="right"
   //          size={"default"}
   //          closable={false}
   //          onClose={onDrawerClose}
   //          visible={assignVisible}
   //          extra={
   //             <Space>
   //                <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
   //             </Space>
   //          }
   //       >
   //          <SimpleBarReact style={{height:"calc(100vh - 110px)"}}>
   //             <LessonAssessmentAssignTool
   //                mode={ "drawer" } 
   //                options={ assOptions } 
   //                parentCallback={ handleSetOptions } 
   //                saveCallback={ handleSaveAssignment } 
   //             />
   //          </SimpleBarReact>
   //       </Drawer>
   //    </>
   // }   

   // const handleChangeTerm = (e) => {
   //    const value = e + '';
   //    // console.log(e)
   //    // console.log(e.target)
   //    console.log(value)

   //    var items = dataContainer.filter(item => item.term === value)
   //    setData( items )
   // }

   // const NavigateEdit = () => {
   //    return <>
   //       <Navigate to={`/lesson/editlesson?Id=${key}`} replace state={{}}/>
   //    </>
   // }

   const handleRefreshList = () => {
      setLoading(true);

      let url = `${process.env.REACT_APP_API_ZOOM}/zoompickerlist`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            // setZoomPickerList(data);
            // setZoomPickerModal(true);
            setLoading(false);
            setZoomPickerOptions({
               Modal: true,
               List: data
            });
         }
      }, (error) => {
         setLoading(false);
         console.log(error.message);
      });
   }

   const showZoomObserveClass = () => {
      setLoading(true);

      let url = `${process.env.REACT_APP_API_ZOOM}/zoomobservelist`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            setLoading(false);
            setZoomObserveOptions({
               Modal: true,
               List: data
            });
         }
      }, (error) => {
         setLoading(false);
         console.log(error.message);
      });
   }

   const handleObserveClass = (section) => {
      //-- Observe class routine

      let url = `${process.env.REACT_APP_API_ZOOM}/zoomgetmeeting?id=${g_zoom_account}`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let zoomMeeting = response.data;

            //-- Add observer to breakout room (section name) where current lesson is assigned
            var teacherEmail = g_email;
            var classSection = section;

            var rooms = zoomMeeting.settings.breakout_room.rooms;

            for (var i = 0; i < rooms.length; i++) {
               var participants = rooms[i].participants;
               var itemIdx = participants.findIndex(item => item === teacherEmail);

               //-- Remove observer from other breakout rooms if included
               if (itemIdx >= 0)
                  participants.splice(itemIdx, 1);

               //-- Add observer to breakout room where current lesson is assigned
               if (rooms[i].name === classSection) {
                  rooms[i].participants.push(teacherEmail);
               }

               //--Update room participants
               rooms[i].participants = participants;
            }

            //-- (Start) Update breakout rooms data
            const options = {
               "settings": {
                  "breakout_room": {
                     "rooms": rooms,
                  }
               }
            }

            url = `${process.env.REACT_APP_API_ZOOM}/zoomupdatemeeting?id=${g_zoom_account}`;
            axios.patch(url, options, { headers: {} }).then((response) => {
               if (response.data) {
                  window.open(zoomMeeting.join_url, "_blank").focus();
               }
            }, (error) => {
               Modal.error({
                  title: 'Lesson',
                  content: 'Looks like there was an error joining zoom meeting',
                  centered: true,
               });
            });
            //-- (End) Update breakout rooms data
         }
      }, (error) => {
         Modal.error({
            title: 'Lesson',
            content: 'Looks like there was an error joining zoom meeting',
            centered: true,
         });
      });

      // if (join_url !== "")
      //    window.open(join_url, "_blank").focus();
      // else {
      //    Modal.info({
      //       content: 'Unable to join the class',
      //       centered: true
      //    });
      // }
   }

   // const handleObserveClass = (join_url) => {
   //    //-- Observe class routine
   //    if (join_url !== "")
   //       window.open(join_url, "_blank").focus();
   //    else {
   //       Modal.info({
   //          content: 'Unable to join the class',
   //          centered: true
   //       });
   //    }
   // }

   const handleRefreshObserveList = () => {
      setLoading(true);

      let url = `${process.env.REACT_APP_API_ZOOM}/zoomobservelist`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            setLoading(false);
            setZoomObserveOptions({
               Modal: true,
               List: data
            });
         }
      }, (error) => {
         setLoading(false);
         console.log(error.message);
      });
   }

   function colorize() {
      var r = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2),
         g = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2),
         b = ('0' + (Math.random() * 255 | 0).toString(16)).slice(-2);
      return '#' + r + g + b;
   }

   const itemSubjectClick = (subject) => {
      setSubjectSelected(subject)
      setViewType(1)
      var result = dataContainer.filter(p => p.subject === subject)
      setData(result);
   }



   const displaySubjectGroups = (d) => {
      let subjects = []
      if (viewType === 0) {
         d = Object.keys(d).sort().reduce(
            (obj, key) => {
               obj[key] = d[key];
               return obj;
            },
            {}
         );
         for (const key in d) {
            var item = { subjectName: key, TeacherName: d[key][0].teacher_name }
            subjects.push(item)
         }
      }

      return (
         <>
            {
               loading
                  ?
                  <div className='center-empty-space'>
                     <Spin size='large' tip="Loading, please wait..." />
                  </div>
                  :
                  (subjects.length > 0 && viewType === 0 && !loading)
                     ?
                     <>
                        <div className="ag-format-container">
                           <div className="ag-courses_box">
                              {
                                 subjects.map((subject, i) => {
                                    //var color = "#e44002"
                                    var color = colorize()
                                    return (

                                       <div className="ag-courses_item" onClick={() => itemSubjectClick(subject.subjectName)}>
                                          <a href="#" className="ag-courses-item_link">
                                             <div className="ag-courses-item_bg" style={{ backgroundColor: color }}></div>
                                             <div className="ag-courses-item_title">
                                                {subject.subjectName}
                                             </div>

                                             <div className="ag-courses-item_date-box">
                                                Teacher :
                                                <span className="ag-courses-item_date">
                                                   {subject.TeacherName}
                                                </span>
                                             </div>
                                          </a>
                                       </div>
                                    )
                                 })

                              }

                           </div>
                        </div>
                     </>
                     :
                     (data.length > 0 && viewType === 1 && !loading ?
                        <>
                           <div>
                              <Button className='button-shadow' type="primary" shape='round' onClick={() => setViewType(0)}>
                                 {"< Go back"}
                              </Button>
                           </div>
                           <Table
                              loading={loading}
                              size='small'
                              rowKey={'uid'}
                              columns={columns}
                              dataSource={data}
                              pagination={{ position: ['topCenter'] }}
                              // style={{ minWidth: "1000px" }}
                              scroll={{
                                 // y: 'calc(100svh - 20rem)',
                                 x: '1000px',
                                 y: 'calc(100svh - 10.5rem)',
                              }}
                              footer={() => 'Total Records: ' + data.length}
                           />
                        </>
                        :
                        <div className='center-empty-space'>
                           <Empty image="../images/brainee_mascot.png" description="No records found" />
                        </div>
                     )
            }
         </>
      )
   }

   const displayPaneTable = (data) => {
      return (
         <>
            {
               loading
                  ?
                  <div className='center-empty-space'>
                     <Spin size='large' tip="Loading, please wait..." />
                  </div>
                  :
                  (data.length > 0 && !loading)
                     ?
                     <Table
                        loading={loading}
                        size='small'
                        rowKey={'uid'}
                        columns={columns}
                        dataSource={data}
                        pagination={{ position: ['topCenter'] }}
                        // style={{ minWidth: "1000px" }}
                        scroll={{
                           // y: 'calc(100svh - 20rem)',
                           x: '1000px',
                           y: 'calc(100svh - 18.5rem)',
                        }}
                        footer={() => 'Total Records: ' + data.length}
                     />
                     :
                     <div className='center-empty-space'>
                        <Empty image="../images/brainee_mascot.png" description="No records found" />
                     </div>
            }
         </>
      )
   }

   const handleUserChange = (value) => {
      setUser_Id(value)
   };

   const handleShowViewed = (id) => {
      let url = `${process.env.REACT_APP_LMS_API}api/LessonAttendance/getattendance?id=${id}`;
      axios.get(url, { headers: {} }).then((response) => {
         if (response.data) {
            let data = response.data;
            setLessonAttendanceList(data);
            setShowLessonAttendanceModal(true);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const searchLesson = (str) => {
      var filerData = dataContainer.filter(p => (p.title.toLowerCase().includes(str.toLowerCase()) || p.subject.toLowerCase().includes(str.toLowerCase())) && p.subject === subjectSelected)
      setData(filerData)
   }

   return (
      <>
         {/* { assOptions && renderDrawer() } */}

         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">Lesson</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Lesson</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               {isAdmin || role === 'Teacher' || role === 'Co-Teacher'
                  ?
                  // <Row gutter={12}>

                  <div className='search-columns-3'>
                     {isAdmin
                        ?
                        <>
                           <Space direction='horizontal' size={'small'}>
                              <span>View as </span>
                              <Select
                                 showSearch
                                 placeholder="Search to Select"
                                 optionFilterProp="children"
                                 filterOption={(input, option) => option.children.includes(input)}
                                 defaultValue={user_full_name} style={{ width: "250px" }} onChange={(value) => handleUserChange(value)} >
                                 {
                                    userList.map((item) => {
                                       var fullname = (item.first_Name + " " + item.last_Name).trimEnd()
                                       return (
                                          <Option value={item.id}>{fullname}</Option>
                                       )
                                    })
                                 }
                              </Select>
                           </Space>
                           <Space direction='horizontal' size={'small'}>
                              <Input addonBefore={<SearchOutlined />} placeholder="Search Lesson..." onChange={(e) => searchLesson(e.target.value)} />
                           </Space>
                           <Tooltip placement="bottom" title='Create new lesson'>
                              <Button className='button-shadow' type="primary" size="large" shape='round' icon={<PlusOutlined />} style={{ maxWidth: '22ch' }} onClick={() => handleCreateLesson()}>
                                 <Text ellipsis style={{ color: '#FFF' }}>Create Lesson</Text>
                              </Button>
                           </Tooltip>
                        </>
                        :
                        <>
                           <Space direction='horizontal' size={'small'}><div></div></Space>
                           <Space direction='horizontal' size={'small'}>
                              <Input addonBefore={<SearchOutlined />} placeholder="Search Lesson ..." onChange={(e) => searchLesson(e.target.value)} />
                           </Space>
                           <Tooltip placement="bottom" title='Create new lesson'>
                              <Button className='button-shadow' type="primary" size="large" shape='round' icon={<PlusOutlined />} style={{ maxWidth: '22ch' }} onClick={() => handleCreateLesson()}>
                                 <Text ellipsis style={{ color: '#FFF' }}>Create Lesson</Text>
                              </Button>
                           </Tooltip>
                        </>
                     }
                  </div>
                  // </Row>
                  :
                  <></>
               }

               <div className="dashboard-item-inner3">
                  {Number(user_id) > 0 &&
                     <Tabs defaultActiveKey={defaultTab} centered onChange={(key) => handleTabChange(key)}>
                        {(isAdmin || role === 'Teacher' || role === 'Co-Teacher') &&
                           <TabPane tab={(<Tooltip title="List of all lessons">
                              <span>Lesson List</span>
                           </Tooltip>
                           )} key="1">
                              {displaySubjectGroups(dataSubject)}
                           </TabPane>}
                        {(isAdmin || role === 'Teacher' || role === 'Co-Teacher') &&
                           <TabPane tab={(<Tooltip title="List of shared lessons">
                              <span>Shared Lessons</span>
                           </Tooltip>
                           )} key="2">
                              {displaySubjectGroups(dataSubject)}
                           </TabPane>}
                        <TabPane tab={(
                           <Tooltip title="Current assigned lessons">
                              <span>Current</span>
                           </Tooltip>
                        )} key="3">
                           {displaySubjectGroups(dataSubject)}
                        </TabPane>
                        <TabPane tab={(
                           <Tooltip title="Upcoming assigned lessons">
                              <span>Upcoming</span>
                           </Tooltip>
                        )} key="4">
                           {displaySubjectGroups(dataSubject)}
                        </TabPane>
                        <TabPane tab={(
                           <Tooltip title="Past assigned lessons">
                              <span>Past</span>
                           </Tooltip>
                        )} key="5">
                           {displaySubjectGroups(dataSubject)}
                        </TabPane>
                     </Tabs>}
               </div>
            </div>
         </div>



         <Modal
            title="Student Viewing"
            centered
            open={publishModal}
            onCancel={() => setPublishModal(false)}
            footer={[
               <Button key="add" type="primary" onClick={handleonOkPublish}>
                  Accept
               </Button>,
            ]}
         >
            <div style={{ width: "100%", textAlign: "center" }}>
               <Radio.Group buttonStyle="solid" value={studentviewing} onChange={(e) => handleChangeStudentViewing(e)} style={{ textAlign: "center" }}>
                  <Radio.Button value="allowed">Allowed</Radio.Button>
                  <Radio.Button value="notallowed">Not Allowed</Radio.Button>
               </Radio.Group>
            </div>
         </Modal>

         <Modal
            title="Lesson Sharing"
            centered
            open={shareModal}
            onCancel={() => setShareModal(false)}
            footer={[
               <Button key="add" type="primary" onClick={handleonOkShare}>
                  Accept
               </Button>,
            ]}
         >
            <div style={{ width: "100%", textAlign: "center" }}>
               <Radio.Group buttonStyle="solid" value={lessonsharing} onChange={(e) => handleChangeLessonSharing(e)}>
                  <Radio.Button value="shared">Shared</Radio.Button>
                  <Radio.Button value="unshared">UnShared</Radio.Button>
               </Radio.Group>
            </div>
         </Modal>

         <Modal
            title="Zoom Picker"
            destroyOnClose={true}
            centered
            open={zoomPickerOptions.Modal}
            onOk={() => setZoomPickerOptions({ Modal: false, List: [] })}
            onCancel={() => handleRefreshList()}
            okText={"Close"}
            cancelText={"Refresh List"}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            width='75%'
         >
            <div className='student_list_container'>
               <Table
                  rowKey={'uid'}
                  loading={loading}
                  size='small'
                  columns={zoomPickerColumns}
                  dataSource={zoomPickerOptions.List}
                  pagination={{ pageSize: 10, position: ['topCenter', 'bottomCenter'] }}
               />
            </div>
         </Modal>

         <Modal
            title="Zoom Observe Class"
            destroyOnClose={true}
            centered
            open={zoomObserveOptions.Modal}
            onOk={() => setZoomObserveOptions({ Modal: false, List: [] })}
            onCancel={() => handleRefreshObserveList()}
            // okText={"Close"}
            // cancelText={"Refresh List"}
            // okButtonProps={{shape:"round"}}
            // cancelButtonProps={{shape:"round"}}
            width='75%'
            footer={[
               <>
                  <Row gutter={12} align="middle">
                     <Col span={12} style={{ textAlign: "left" }}>
                        {/* <Text ellipsis><i>Note: Make sure to refresh the list before joining class</i></Text> */}
                     </Col>
                     <Col span={12} style={{ textAlign: "right" }}>
                        {/* <Button type='default' shape='round' size='middle' onClick={() => handleRefreshObserveList()}>Refresh List</Button> */}
                        <Button type='primary' shape='round' size='middle' onClick={() => setZoomObserveOptions({ Modal: false, List: [] })}>Close</Button>
                     </Col>
                  </Row>
               </>,
            ]}
         >
            <div className='student_list_container'>
               <Table
                  rowKey={'uid'}
                  loading={loading}
                  size='small'
                  columns={zoomObserveColumns}
                  dataSource={zoomObserveOptions.List}
                  pagination={{ pageSize: 10, position: ['bottomCenter'] }}
               />
            </div>
         </Modal>

         <Modal
            title="Students who viewed the lesson"
            destroyOnClose={true}
            centered
            open={showLessonAttendanceModal}
            cancelText={"Close"}
            okButtonProps={{ shape: "round", hidden: "true" }}
            cancelButtonProps={{ shape: "round" }}
            onCancel={() => setShowLessonAttendanceModal(false)}
            width='40%'
         >
            <div className='student_list_container'>
               <Table
                  rowKey={'uid'}
                  loading={loading}
                  size='small'
                  columns={lessonAttendanceColumns}
                  dataSource={lessonAttendanceList}
                  pagination={{ pageSize: 10, position: ['bottomCenter'] }}
               />
            </div>
         </Modal>

         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

      </>
   );
}