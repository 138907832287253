/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Typography, Image, Divider, List, Button, Empty, Breadcrumb } from 'antd';

import Utils from '../../misc/Utils';
import Split from 'react-split';

import Iframe from 'react-iframe';
import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import ReactPlayer from 'react-player'
// import { Editor } from '@tinymce/tinymce-react';
// import * as AntDIcons from '@ant-design/icons';
// import Wysiwyg from '../../components/wysiwyg/wysiwyg';
import Wysiwyg2 from '../../components/wysiwyg/wysiwyg2';

import './MasteryAssessment.css';
import '../../../node_modules/simplebar/src/simplebar.css';

const { Title } = Typography;

export function MasteryInstruction(props) {
   const location = useLocation();
   const navigate = useNavigate();
   // const editorRef = useRef(null);

   // const history = createBrowserHistory();

   // const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   // const name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);

   // const masteryrec = useSelector(state => state.masteryrec);

   const assign_id = location.state.assign_id;
   const mastery_id = location.state.mastery_id;
   // const assignInfo = location.state.assignInfo;
   const instruction_data = location.state.instruction_data;
   const origin = location.state.activetab;

   const [currentInstructionDoc, setCurrentInstructionDoc] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();
   }, []);

   const renderPreviewer = (document) => {
      if (document !== null && document !== '') {
         var re = /(?:\.([^.]+))?$/;
         var ext = re.exec(document.toLowerCase());

         var subdomain = localStorage.getItem("lms-subdomain");
         var url = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}/${document}`;

         switch (ext[1]) {
            case "pdf":
               return <>
                  <RenderPDFViewer url={url} height={"calc(100vh - 55px)"} showdownload={true} showprint={true} showpagecontrols={true} showfullscreen={true} />
               </>

            case "doc":
            case "docx":
            case "xls":
            case "xlsx":
            case "ppt":
            case "pptx":
               return <>
                  <Iframe
                     id="msdoc-iframe"
                     title="msdoc-iframe"
                     src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                     frameBorder="0"
                     // height={"calc(100vh - 55px)"}
                     height='100%'
                     width='100%'
                     loading='eager'
                  // sandbox='allow-scripts allow-modal'
                  />
               </>

            case "png": case "jpg": case "jpeg":
               return <>
                  {/* <SimpleBarReact style={{height:"calc(100vh - 200px)"}}> */}
                  <Image
                     src={url}
                  // width={"100%"}
                  // height={"calc(100vh - 55px)"}
                  // height="100%"
                  />
                  {/* </SimpleBarReact> */}
               </>

            case "mp4": case "avi":
               return <>
                  <ReactPlayer
                     controls={true}
                     width='100%'
                     // height={"calc(100vh - 55px)"}
                     height="100%"
                     url={url.replace(/ /g, '+').replace(/#/g, '%23')}
                  />
               </>
         }
      }
      else {
         // if (document_link !== null && document_link !== '')
         //    return <>
         //       <div className='preview'>
         //          <a href={`${document_link}`} title="Click here to view the document" target={"_blank"}>Click here to view the document</a>
         //       </div>
         //    </>
         // else
         return <>
            {
               instruction_data.rec_docs !== null && instruction_data.rec_docs !== ''
                  ?
                  <div className='modal-document_viewer'>
                     <Empty description='Click an item in the document list to view.' image="../images/brainee_mascot.png" />
                  </div>
                  :
                  <div className='modal-document_viewer'>
                     <Empty description='No uploaded documents found.' image="../images/brainee_mascot.png" />
                  </div>
            }

         </>
      }
   }

   const handleViewInstructionDoc = (document) => {
      setCurrentInstructionDoc(document);
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assign_id}&mastery_id=${mastery_id}&origin=${origin}`)}>Start</a></li>
                           <li><span>/</span></li>
                           <li className="active">Teacher's Recommendation</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessment</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assign_id}&mastery_id=${mastery_id}&origin=${origin}`)}>Start</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Teacher's Recommendation</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>
                  </div>

                  <div className='recommendation_view'>
                     {renderPreviewer(currentInstructionDoc)}
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <>
                        {  //assInfo.Question_sheet && 
                           <div className='answer-sheet-content'>
                              <Divider orientation="left">
                                 <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Description</Title>
                              </Divider>
                              <Wysiwyg2
                                 content={instruction_data.rec_desc}
                                 height={250}
                                 contentHeight={210}
                                 disabled={true}
                              />
                              {/* <Editor
                                 apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                                 onInit={(evt, editor) => editorRef.current = editor}
                                 initialValue={instruction_data.rec_desc}
                                 disabled
                                 init={{
                                    menubar: false,
                                    height: 250,
                                    statusbar: false,
                                    plugins: [
                                       'advlist autolink lists link image charmap print preview anchor',
                                       'searchreplace visualblocks code',
                                       'insertdatetime media table paste code help wordcount emoticons'
                                    ],
                                    toolbar: false,
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                 }}
                              /> */}
                              <Divider orientation="left">
                                 <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>View Documents</Title>
                              </Divider>
                              {
                                 (instruction_data.rec_docs !== null && instruction_data.rec_docs !== '') &&
                                 <>

                                    <List
                                       size="small"
                                       dataSource={instruction_data.rec_docs}
                                       renderItem={(item, idx) =>
                                          <List.Item
                                          >
                                             <Button className='button-shadow'
                                                size='middle'
                                                type='primary'
                                                shape='round'
                                                block
                                                onClick={() => handleViewInstructionDoc(item)}
                                             >
                                                {instruction_data.rec_doc_names[idx]}
                                             </Button>
                                          </List.Item>}
                                    />
                                 </>
                              }

                           </div>
                        }
                     </>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
